import React, {useState} from "react";
import {Icon, Paper, Tab, Tabs, Typography} from "@material-ui/core";
import {FusePageCarded, FusePageSimple} from "../../../@fuse";
import AccountSettings from "./AccountSettings";
import PasswordSettings from "./PasswordSettings";

export default function Settings() {
    const [tabValue, setTabValue] = useState(0);
    return (
        <FusePageCarded
            header={
                <div className='flex flex-row items-center px-24'>
                     <Icon color={"secondary"}>settings</Icon>
                     <Typography color={"secondary"} className='text-2xl pl-8'>Settings</Typography>
                 </div>
            }
            contentToolbar={
                <Tabs
                    value={tabValue}
                    onChange={(e, value) => setTabValue(value)}
                    indicatorColor="secondary"
                    textColor="secondary"
                    variant="scrollable"
                    className="w-full h-52 mt-12"
                >
                    <Tab className="text-16 font-600 normal-case" label="Account Settings"/>
                    <Tab className="text-16 font-600 normal-case" label="Change Password"/>
                </Tabs>
            }


            content={
                <div>
                    {tabValue === 0 &&
                    (
                        <AccountSettings/>
                    )}
                    {tabValue === 1 &&
                    (
                        <PasswordSettings/>
                    )}
                </div>

            }
        />

    )
}
