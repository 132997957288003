import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button, Icon, InputAdornment, Paper, Typography} from "@material-ui/core";
import Formsy from "formsy-react";
import {TextFieldFormsy} from "../../../@fuse";
import axios from "axios";
import {API_BASE_URL} from "../../fuse-configs/constants";
import {setUserData} from "../../auth/store/actions";
import {showMessage} from "../../store/actions";

export default function PasswordSettings() {
    const user = useSelector(({auth}) => auth.user);
    const [canSubmit, setCanSubmit] = useState(false);
    const dispatch = useDispatch();

    const onSubmit = (data) => {
        // call API + update local.

        axios.post(API_BASE_URL + 'auth/update/', data)
            .then(res => {
                dispatch(setUserData(res.data));
                dispatch(showMessage({
                    message: "Password changed successfully!",
                    variant: 'success'
                }))
                localStorage.setItem('jwt_access_token', res.data.token);
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.token;
            }).catch(err => {
            dispatch(showMessage({
                message: (err.response && err.response.data && err.response.data.msg) || "Password change failed",
                variant: 'error'
            }))
        })

    }

    const disableButton = () => {
        setCanSubmit(false)
    };

    const enableButton = () => {
        setCanSubmit(true)
    };

    return (
        <Paper className='m-12 p-12 block max-w-sm rounded-none shadow-0 border border-gray-400'>
            <Formsy
                onValidSubmit={onSubmit}
                onValid={enableButton}
                onInvalid={disableButton}
                className="flex flex-col justify-center w-full"
            >
                <TextFieldFormsy
                    className="mb-16"
                    type="password"
                    name="old_password"
                    label="Current Password"
                    variant="outlined"
                    required
                />
                <TextFieldFormsy
                    className="mb-16"
                    type="password"
                    name="password"
                    label="New Password"
                    InputProps={{
                        endAdornment: <InputAdornment position="end"><Icon className="text-20"
                                                                           color="action">vpn_key</Icon></InputAdornment>
                    }}
                    variant="outlined"
                    required
                />
                <TextFieldFormsy
                    className="mb-16"
                    type="password"
                    name="confirm_new_password"
                    label="Confirm New Password"
                    validations={{
                        equalsField: "password"
                    }}
                    validationErrors={{
                        equalsField: "Password is not matching"
                    }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end"><Icon className="text-20"
                                                                           color="action">vpn_key</Icon></InputAdornment>
                    }}
                    variant="outlined"
                    required
                />

                <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    className="w-full mx-auto mt-12 normal-case"
                    aria-label="Update"
                    disabled={!(canSubmit)}
                    value="legacy"
                >
                    Change Password
                </Button>

            </Formsy>

        </Paper>

    )
}
