import React from 'react';
import {Redirect} from 'react-router-dom';
import {FuseUtils} from '@fuse';
import {AuthConfig} from "../main/auth/AuthConfig";
import {HomeConfig} from "../main/home/HomeConfig";
import {authRoles} from "../auth";
import {UsersConfig} from "../main/users/UsersConfig";
import {CoreConfig} from "../main/core/CoreConfig";
import Landing from "../main/core/Landing";
import {SettingsConfig} from "../main/settings/SettingsConfig";
import {GenerateIDSConfig} from "../main/generate-ids/GenerateIDSConfig";
import {AskGenerateIDSConfig} from "../main/ask-generate-ids/AskGenerateIDSConfig";
import {IDSConfig} from "../main/ids/IDSConfig";
import {InvoiceConfig} from "../main/invoice/InvoiceConfig";
import BlogConfig from "../main/blog/BlogConfig";
import BlogConfigAdmin from "../main/blog/BlogConfigAdmin";
import BlogConfigOutsideApp from "../main/blog/BlogConfigOutsideApp";


const LandingPageSettings = {
    layout: {
        config: {
            navbar: {
                display: false
            },
            toolbar: {
                display: false
            },
            footer: {
                display: true
            }
        }

    }
}

const routeConfigs = [
    AuthConfig,
    HomeConfig,
    UsersConfig,
    CoreConfig,
    SettingsConfig,
    GenerateIDSConfig,
    AskGenerateIDSConfig,
    IDSConfig,
    InvoiceConfig,
    BlogConfigOutsideApp,
    BlogConfigAdmin,
    BlogConfig,
];

const routes = [
    ...FuseUtils.generateRoutesFromConfigs(routeConfigs),
    {
        path: '/',
        exact: true,
        role: authRoles.user,
        component: Landing,
        settings: LandingPageSettings
    },
    {
        component: () => <Redirect to='/'/>,
    }
];

export default routes;
