import React, {Component} from 'react'
import {withStyles, Card, CardContent, Typography} from '@material-ui/core';
import {FuseAnimate} from '@fuse';
import {Link, withRouter} from 'react-router-dom';
import {Button, Divider, InputAdornment, Icon} from '@material-ui/core';
import {TextFieldFormsy} from '@fuse';
import Formsy from 'formsy-react';
import {bindActionCreators} from 'redux';
import connect from 'react-redux/es/connect/connect';
import * as authActions from 'app/auth/store/actions';
import LinearProgress from "@material-ui/core/LinearProgress";
import {Checkbox} from "@material-ui/core";
import {showMessage} from "../../store/actions";
import Header from "../core/components/Header";
import Footer from "../core/components/Footer";
import ReCAPTCHA from "react-google-recaptcha";
import {CAPTCHA_SITE_KEY} from "../../fuse-configs/constants";



const styles = theme => ({
    root: {
        // background: 'linear-gradient(to right, ' + theme.palette.primary.dark + ' 0%, ' + darken(theme.palette.primary.dark, 0.5) + ' 100%)',
        background: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText
    }
});

class Signup extends Component {

    constructor(props) {
        super(props);
    }


    state = {
        canSubmit: false,
        loader: false,
        checkedTerms: false,
        captchaValue:null
    };

    setLoader = (val) => {
        this.setState({loader: val});
    };


    form = React.createRef();

    disableButton = () => {
        this.setState({canSubmit: false});
    };

    enableButton = () => {
        this.setState({canSubmit: true});
    };

    toggleTermsCheck = () => {
        this.setState({checkedTerms: !this.state.checkedTerms})
    }

    onSubmit = (model) => {
        if(this.state.captchaValue) {
            this.props.submitRegister(model, this.signupCallback);
        }
    };

    signupCallback = () => {
        this.form.reset()
    }

    // captcha

    onCaptchaChanged = (value) => {
        this.setState({captchaValue:value})
    }


    render() {
        const {register} = this.props;
        const {canSubmit, checkedTerms, captchaValue} = this.state;

        return (
            <div>
                <Header/>
                <div className="pb-160 h-full items-center">

                    <FuseAnimate animation={{translateY: [0, '10%']}}>

                        <Card className="w-full max-w-sm m-auto rounded-12 mt-24 shadow-md hover:shadow-none">

                            <CardContent className="flex flex-col items-center justify-center p-32 ">

                                <img src='/assets/logo-2.png' className='w-64 mb-12'/>

                                <Typography variant="h6" className="text-center md:w-full mb-32 font-semibold"
                                            color={"primary"}>Welcome! Sign Up</Typography>


                                <div className="w-full">
                                    <Formsy
                                        onValidSubmit={this.onSubmit}
                                        onValid={this.enableButton}
                                        onInvalid={this.disableButton}
                                        ref={(form) => this.form = form}
                                        className="flex flex-col justify-center w-full"
                                    >
                                        <div className='flex flex-row'>
                                            <TextFieldFormsy
                                                className="mb-16 w-full"
                                                type="text"
                                                name="first_name"
                                                label="First Name"
                                                validations={{
                                                    isAlpha: true,
                                                }}
                                                validationErrors={{
                                                    isAlpha: 'Please enter a valid name',
                                                }}
                                                InputProps={{
                                                    autocomplete: "off"
                                                }}
                                                variant="outlined"
                                                required

                                            />
                                            <TextFieldFormsy
                                                className="mb-16 ml-5 w-full"
                                                type="text"
                                                name="last_name"
                                                label="Last Name"
                                                validations={{
                                                    isAlpha: true,
                                                }}
                                                validationErrors={{
                                                    isAlpha: 'Please enter a valid name',
                                                }}
                                                InputProps={{
                                                    autocomplete: "off"
                                                }}
                                                variant="outlined"
                                                required

                                            />

                                        </div>
                                        <TextFieldFormsy
                                            className="mb-16"
                                            type="text"
                                            name="email"
                                            label="Official Email Address"
                                            validations={{
                                                isEmail: true,
                                                maxLength: 50,
                                            }}
                                            validationErrors={{
                                                isEmail: 'You have to type valid email',
                                                maxLength: 'You can not type in more than 50 characters',
                                            }}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end"><Icon className="text-20"
                                                                                                   color="action">email</Icon></InputAdornment>,
                                                autocomplete: "off"
                                            }}
                                            variant="outlined"
                                            required

                                        />
                                        <TextFieldFormsy
                                            className="mb-16"
                                            type="password"
                                            name="password"
                                            label="Password"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end"><Icon className="text-20"
                                                                                                   color="action">vpn_key</Icon></InputAdornment>
                                            }}
                                            variant="outlined"
                                            required
                                        />
                                        <TextFieldFormsy
                                            className="mb-16"
                                            type="password"
                                            name="confirmPassword"
                                            label="Confirm Password"
                                            validations={{
                                                equalsField:"password"
                                            }}
                                            validationErrors={{
                                                equalsField:"Password is not matching"
                                            }}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end"><Icon className="text-20"
                                                                                                   color="action">vpn_key</Icon></InputAdornment>
                                            }}
                                            variant="outlined"
                                            required
                                        />
                                        <TextFieldFormsy
                                            className="mb-16"
                                            type="text"
                                            name="mobile"
                                            label="Mobile No."
                                            validations={{
                                                isNumeric:true,
                                                minLength: 10,
                                            }}
                                            validationErrors={{
                                                isNumeric:"Please enter a valid mobile",
                                                minLength: 'Min character length is 10',
                                            }}
                                            InputProps={{
                                                autocomplete: "off"
                                            }}
                                            variant="outlined"

                                        />
                                        <TextFieldFormsy
                                            className="mb-16"
                                            type="text"
                                            name="organization"
                                            label="Organization"
                                            InputProps={{
                                                autocomplete: "off"
                                            }}
                                            variant="outlined"
                                            required
                                        />

                                        <TextFieldFormsy
                                            className="mb-16"
                                            type="text"
                                            name="street"
                                            label="Billing Address"
                                            rows={2}
                                            multiline
                                            validations={{
                                                minLength: 8
                                            }}
                                            validationErrors={{
                                                minLength: 'Min character length is 8'
                                            }}
                                            required
                                            variant="outlined"
                                        />
                                        <TextFieldFormsy
                                            className="mb-16"
                                            type="text"
                                            name="city"
                                            label="City"
                                            validations={{
                                                minLength: 3
                                            }}
                                            validationErrors={{
                                                minLength: 'Min character length is 3'
                                            }}
                                            required
                                            variant="outlined"
                                        />
                                        <TextFieldFormsy
                                            className="mb-16"
                                            type="text"
                                            name="pincode"
                                            label="Pincode"
                                            validations={{
                                                minLength: 6
                                            }}
                                            validationErrors={{
                                                minLength: 'Min character length is 6'
                                            }}
                                            required
                                            variant="outlined"
                                        />
                                        <TextFieldFormsy
                                            className="mb-16"
                                            type="text"
                                            name="state"
                                            label="State"
                                            validations={{
                                                minLength: 3
                                            }}
                                            validationErrors={{
                                                minLength: 'Min character length is 3'
                                            }}
                                            required
                                            variant="outlined"
                                        />
                                        <TextFieldFormsy
                                            className="mb-16"
                                            type="text"
                                            name="country"
                                            label="Country"
                                            validations={{
                                                minLength: 3
                                            }}
                                            validationErrors={{
                                                minLength: 'Min character length is 3'
                                            }}
                                            required
                                            variant="outlined"
                                        />

                                        <Typography className="font-medium text-gray-700 my-4"><Checkbox
                                            onClick={this.toggleTermsCheck} checked={this.state.checkedTerms}/>
                                            By signing up, you are accepting our <a href='/terms-and-conditions'
                                                                                    target='_blank'
                                                                                    className='text-blue cursor-pointer'>Terms
                                                & Conditions</a>
                                        </Typography>

                                        <ReCAPTCHA
                                            sitekey={CAPTCHA_SITE_KEY}
                                            onChange={this.onCaptchaChanged}
                                        />


                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="secondary"
                                            className="w-full mx-auto mt-12 normal-case"
                                            aria-label="LOG IN"
                                            disabled={!(canSubmit && checkedTerms && captchaValue)}
                                            value="legacy"
                                        >
                                            Sign Up
                                        </Button>

                                    </Formsy>


                                </div>


                                <div className="flex flex-col pt-16 justify-start w-full">
                                <span className="font-medium">Already an account? <Link
                                    to={'/login'}>Sign In</Link></span>
                                </div>

                            </CardContent>
                            {register.loading && (
                                <LinearProgress color="secondary"/>
                            )}
                        </Card>
                    </FuseAnimate>
                </div>
                <Footer/>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        submitRegister: authActions.submitRegister,
        showMessage: showMessage
    }, dispatch);
}

function mapStateToProps({auth}) {
    return {
        register: auth.register,
        user: auth.user
    }
}

export default withStyles(styles, {withTheme: true})(withRouter(connect(mapStateToProps, mapDispatchToProps)(Signup)));
