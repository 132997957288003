import React from "react";
import Header from "./components/Header";
import {Paper} from "@material-ui/core";

class PrivacyPolicy extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        page: null
    };

    componentDidMount() {
        fetch("static_html_files/privacy_policy.html")
            .then(result => {
                return result.text();
            })
            .then(page => {
                this.setState(
                    {
                        page: {__html: page}
                    }
                );
            });
    }

    render() {
        const {page} = this.state;
        return (
            <>
                <Header {...this.props}/>
                {this.props.hideNavbar ?
                    <Paper className="pb-24 max-w-lg mx-auto my-24 items-center shadow hover:shadow-none">
                        <div
                            dangerouslySetInnerHTML={page && page}
                        />
                    </Paper>
                    :
                    <Paper className="pb-24 max-w-2xl mx-auto my-24 items-center shadow hover:shadow-none">
                        <div
                            dangerouslySetInnerHTML={page && page}
                        />
                    </Paper>

                }
            </>
        );
    }
}

export default PrivacyPolicy;
