import {combineReducers} from 'redux';
import fuse from './fuse';
import auth from 'app/auth/store/reducers';
import quickPanel from 'app/fuse-layouts/shared-components/quickPanel/store/reducers';
import IDS from "./IDS";
import blogReducer from "./blog.reducer";

const createReducer = (asyncReducers) =>
    combineReducers({
        auth,
        fuse,
        IDS,
        quickPanel,
        blog:blogReducer,
        ...asyncReducers
    });

export default createReducer;
