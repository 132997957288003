import XMLParser from 'react-xml-parser';
import {CGI_BIN_URL} from "../../../fuse-configs/constants";


export const GET_APPLICATION_START = "[IDS] GET APPLICATION START";
export const GET_APPLICATION_SUCCESS = "[IDS] GET APPLICATION SUCCESS";
export const GET_APPLICATION_FAILED = "[IDS] GET APPLICATION FAILED";

export const GET_PATENTS_START = "[IDS] GET PATENTS START";
export const GET_PATENTS_SUCCESS = "[IDS] GET PATENTS SUCCESS";
export const GET_PATENTS_FAILED = "[IDS] GET PATENTS FAILED";

export const RESET_IDS_GENERATE_FORM = "[IDS] RESET IDS GENERATE FORM";


export const getApplication = (applicationNumber) => {
    return dispatch => {
        dispatch({
            type: GET_APPLICATION_START,
            payload: {
                msg: 'Retrieving Application Details...',
                applicationNumber: applicationNumber
            }
        })

        if (applicationNumber === "") {
            return dispatch({
                type: GET_APPLICATION_FAILED,
                payload: {
                    msg: "Application number can't be blank"
                }
            })
        }
        let IDType = "applId";
        let searchString = IDType + ":(\"" + applicationNumber + "\")";
        let isnum = /^[0-9]+$/.test(applicationNumber);

        let params = "searchText=" + searchString + "&fl=*&qf=applId&facet=false";

        if (isnum === false) {
            return dispatch({
                type: GET_APPLICATION_FAILED,
                payload: {
                    msg: 'Please enter only digits'
                }
            })
        }

        dispatch({
            type: GET_APPLICATION_START,
            payload: {
                msg: 'Retrieving Application Details...',
                applicationNumber: applicationNumber
            }
        })

        fetch(CGI_BIN_URL + 'uspto_fetch_first.py?' + params,)
            .then(res => res.json())
            .then(data => {
                    let results = data;
                    results = results.queryResults.searchResponse.response;
                    var numberOfEntries = results.numFound;

                    // let queryID = res.queryResults.queryId;
                    //
                    // let msg = ""

                    if (numberOfEntries === 0) {
                        return dispatch({
                            type: GET_APPLICATION_FAILED,
                            payload: {
                                msg: 'No matching entry found.'
                            }
                        })
                    } else {
                        var currentResult = results.docs[0];
                        var applId = currentResult.applId;
                        var appFilingDate = currentResult.appFilingDate;
                        var inventorName = currentResult.inventorName;
                        var appGrpArtNumber = currentResult.appGrpArtNumber;
                        var appExamName = currentResult.appExamName;
                        var appAttrDockNumber = currentResult.appAttrDockNumber;
                        var inventionTitle = currentResult.patentTitle;

                        if (appFilingDate === undefined) {
                            appFilingDate = "";
                        }
                        if (inventorName === undefined) {
                            inventorName = "";
                        }
                        if (appGrpArtNumber === undefined) {
                            appGrpArtNumber = "";
                        }
                        if (appExamName === undefined) {
                            appExamName = "";
                        }
                        if (appAttrDockNumber === undefined) {
                            appAttrDockNumber = "";
                        }
                        if (inventionTitle === undefined) {
                            inventionTitle = "";
                        }

                        const application = {
                            applId: applId,
                            appFilingDate: appFilingDate.toString().split('T')[0],
                            inventorName: inventorName,
                            appGrpArtNumber: appGrpArtNumber,
                            appExamName: appExamName,
                            appAttrDockNumber: appAttrDockNumber,
                            inventionTitle: inventionTitle,
                        }


                        return dispatch({
                            type: GET_APPLICATION_SUCCESS,
                            payload: {
                                application: application,
                                msg: null
                            }
                        })

                    }

                }
            )
            .catch(err => {
                return dispatch({
                    type: GET_APPLICATION_FAILED,
                    payload: {
                        msg: 'Server error in fetching Application Data'
                    }
                })
            })


    }
}

function validateApplIDForDownload(applIDList) {
    // count the number of commas in text
    let applicationsArray = applIDList.split(',');
    let numEntries = applicationsArray.length;
    if (numEntries > 5) {
        return false;
    }
    return true;
}

function formatDate(inputDateString) {

    var formattedDateString = "invalid-date";
    var stringLen = inputDateString.length;

    if (stringLen == 0) {
        return "";
    } else if (stringLen == 8) {
        var year = inputDateString.substring(0, 4);
        var month = inputDateString.substring(4, 6);
        var day = inputDateString.substring(6, 8);

        formattedDateString = year + '-' + month + '-' + day;
    }

    return formattedDateString;
}

function parseSingleApplication(appDoc, dispatch) {

    var country = appDoc["@country"];
    var docNum = appDoc["@doc-number"];
    var kind = appDoc["@kind"];
    var status = appDoc["@status"];

    if (status != undefined) {
        if (status == "not found") {
            var appNumber = "";
            if (kind != undefined) {
                appNumber = country + docNum + kind;
            } else {
                appNumber = country + docNum;
            }
            return appNumber;
        }
    }

    var biblio = appDoc["bibliographic-data"];

    //var docID = biblio["application-reference"]["@doc-id"];

    var inventionTitle = biblio["invention-title"];

    if (inventionTitle != undefined) {
        var englishTitle = 0;
        var num_entries = inventionTitle.length
        if (num_entries > 1) {
            for (var i = 0; i < num_entries; i++) {
                if (inventionTitle[i]["@lang"] == "en") {
                    inventionTitle = inventionTitle[i]["$"];
                    englishTitle = 1;
                    break;
                }
            }
            if (englishTitle == 0) {
                inventionTitle = inventionTitle[0]["$"];
            }
        } else {
            inventionTitle = inventionTitle["$"];
        }
    } else {
        inventionTitle = "";
    }


    var priorityClaims = biblio["priority-claims"];
    var priorityClaim = "";
    var priorityDate = "";
    if (priorityClaims !== undefined) {
        priorityClaim = priorityClaims["priority-claim"];
        if (Array.isArray(priorityClaim)) {
            var documentID = priorityClaim[0]["document-id"];
            if (Array.isArray(documentID)) {
                priorityDate = documentID[0]["date"]["$"];
            } else {
                priorityDate = documentID["date"]["$"];
            }
        } else {
            var documentID = priorityClaim["document-id"];
            if (Array.isArray(documentID)) {
                priorityDate = documentID[0]["date"]["$"];
            } else {
                priorityDate = documentID["date"]["$"];
            }
        }
        priorityDate = formatDate(priorityDate);
    }

    var publicationDate = "";

    var publicationDocumentID = biblio["publication-reference"]["document-id"];

    if (Array.isArray(publicationDocumentID)) {
        publicationDate = publicationDocumentID[0]["date"]["$"];
    } else {
        publicationDate = publicationDocumentID["date"]["$"];
    }
    publicationDate = formatDate(publicationDate);

    var applicants = biblio["parties"]["applicants"];
    var applicantName = "";

    if (applicants != undefined) {
        var applicant = applicants["applicant"];
        if (Array.isArray(applicant)) {
            applicantName = applicant[0]["applicant-name"]["name"]["$"];
        } else {
            applicantName = applicant["applicant-name"]["name"]["$"];
        }
    }

    var inventors = biblio["parties"]["inventors"];
    var firstInventor = "";

    if (inventors != undefined) {
        var inventor = inventors["inventor"];
        if (Array.isArray(inventor)) {
            firstInventor = inventor[0]["inventor-name"]["name"]["$"];
        } else {
            firstInventor = inventor["inventor-name"]["name"]["$"];
        }

    }


    const patent = {
        country: country,
        docNum: docNum,
        kind: kind,
        priorityDate: priorityDate,
        publicationDate: publicationDate,
        applicantName: applicantName,
        firstInventor: firstInventor,
        inventionTitle: inventionTitle
    }


    dispatch({
        type: GET_PATENTS_SUCCESS,
        payload: {
            patent: patent
        }
    })

    return "";

}

// Changes XML to JSON
function xmlToJson(xml) {

    // Create the return object
    var obj = {};

    if (xml.nodeType == 1) { // element
        // do attributes
        if (xml.attributes.length > 0) {
            obj["@attributes"] = {};
            for (var j = 0; j < xml.attributes.length; j++) {
                var attribute = xml.attributes.item(j);
                obj["@attributes"][attribute.nodeName] = attribute.nodeValue;
            }
        }
    } else if (xml.nodeType == 3) { // text
        obj = xml.nodeValue;
    }

    // do children
    if (xml.hasChildNodes()) {
        for(var i = 0; i < xml.childNodes.length; i++) {
            var item = xml.childNodes.item(i);
            var nodeName = item.nodeName;
            if (typeof(obj[nodeName]) == "undefined") {
                obj[nodeName] = xmlToJson(item);
            } else {
                if (typeof(obj[nodeName].push) == "undefined") {
                    var old = obj[nodeName];
                    obj[nodeName] = [];
                    obj[nodeName].push(old);
                }
                obj[nodeName].push(xmlToJson(item));
            }
        }
    }
    return obj;
};


export const getPatents = (patentReferences) => {
    return dispatch => {
        dispatch({
            type: GET_PATENTS_START,
            payload: {
                patentReferences: patentReferences,
                msg: "Retrieving Details..."
            }
        })
        if (patentReferences === "") {
            return dispatch({
                type: GET_PATENTS_FAILED,
                payload: {
                    msg: "No patents found."
                }
            })
        }

        const params = "appNumList=" + patentReferences;

        fetch(CGI_BIN_URL + 'epo_fetch.py?' + params)
            .then(res => res.text())
            .then(dataX => {
                // let data = {}
                if(dataX.startsWith("<")) {
                    var jsonDataFromXml = new XMLParser().parseFromString(dataX);
                    const message = jsonDataFromXml['children'][1]['value']
                    return dispatch({
                        type: GET_PATENTS_FAILED,
                        payload: {
                            msg: message
                        }
                    })
                } else {
                    let data = JSON.parse(dataX)
                    var exchangeDocs = data['ops:world-patent-data']['exchange-documents'];

                    var exchangeDoc = exchangeDocs['exchange-document'];

                    var status = 0;

                    if (Array.isArray(exchangeDoc)) {
                        var arrSize = exchangeDoc.length;
                        var notFoundList = "";
                        var notFoundCount = 0
                        for (var i = 0; i < arrSize; i++) {
                            var ret = parseSingleApplication(exchangeDoc[i], dispatch);
                            if (ret !== "") {
                                status = -1;
                                notFoundCount++;
                                if (notFoundList === "") {
                                    notFoundList = ret;
                                } else {
                                    notFoundList = notFoundList + ", " + ret;
                                }

                            }
                        }
                    } else {
                        var ret = parseSingleApplication(exchangeDoc, dispatch);
                        if (ret !== "") {
                            status = -1;
                            notFoundCount++;
                            notFoundList = ret;
                        }
                    }

                    if (status === 0) {
                        dispatch({
                            type: GET_PATENTS_SUCCESS,
                            payload: {
                                msg: null
                            }
                        })
                    } else {
                        if (notFoundCount === 1) {
                            dispatch({
                                type: GET_PATENTS_SUCCESS,
                                payload: {
                                    msg: notFoundList + " was not found. Please check the Patent/Publication number."
                                }
                            })
                        } else if (notFoundCount > 1) {
                            dispatch({
                                type: GET_PATENTS_SUCCESS,
                                payload: {
                                    msg: notFoundList + " were not found. Please check the Patent/Publication numbers."
                                }
                            })
                        }
                    }

                }

            })
            .catch(err => {
                return dispatch({
                    type: GET_PATENTS_FAILED,
                    payload: {
                        msg: "Invalid Response (1) from Server. Please contact Admin if issue persists"
                    }
                })
            })
    }
}

// returns SB08 file or None
export const getSB08file = (application, patents, NPLReferences) => {
    let npl_refs = []
    for (const i in NPLReferences) {
        npl_refs.concat([{text: NPLReferences[i]}])
    }
    const data = {
        'application-number': application.applId,
        'attr-docket-number': application.appAttrDockNumber,
        'examiner-name': application.appExamName,
        'filing-date': application.appFilingDate,
        'first-inventor': application.inventorName,
        'group-art-unit': application.appGrpArtNumber,
        'epo-data': patents || [],
        'npl-references': npl_refs
    }
    const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
        body: JSON.stringify(data)
    };

    // fetch("https://www.ids.iiprd.com/cgi-bin/sb08_ids_pdf.py", requestOptions)
    //     .then(res=>res.blob())
    //     .then(blob=>{
    //         const file = new File([blob], 'hello.pdf')
    //         console.log(file)
    //
    //         saveAs(blob, "test_0.pdf");
    //     })
    //     .catch(err=>console.log('err'))
}

export const submitGenerate = (patentReferences) => {
}

export const resetIDSGenerateForm = () => ({
    type: RESET_IDS_GENERATE_FORM,
})
