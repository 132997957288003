import React from 'react';
import authRoles from '../../auth/authRoles'
import Home from "./Home";
import TermsAndConditions from "../core/TermsAndConditions";
import Blog from "../core/Blog";
import Feedback from "../core/Feedback";
import ContactUs from "../core/ContactUs";
import PrivacyPolicy from "../core/PrivacyPolicy";
import FAQs from "../core/FAQs";
import WhatIsHatchIDS from "../core/WhatIsHatchIDS";
import AboutUs from "../core/AboutUs";
import BlogDetail from "../core/BlogDetail";

export const HomeConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: true
                },
                toolbar: {
                    display: true
                },
                footer: {
                    display: false
                }
            }
        }
    },
    auth: authRoles.user,
    routes: [
        {
            path: '/home',
            exact:true,
            component: Home
        },
        {
            path: '/what-is-hatch-ids-app',
            component: ()=><WhatIsHatchIDS footer={false} hideNavbar={true} />,
        },
        {
            path: '/about-us-app',
            component: ()=><AboutUs footer={false} hideNavbar={true} />,
        },
        // {
        //     path: '/blog-app',
        //     component: (props)=><Blog footer={false} hideNavbar={true} {...props}/>,
        // },
        // {
        //     path: '/blog-detail-app/:id',
        //     component: (props)=><BlogDetail footer={false} hideNavbar={true} {...props}/>,
        // },
        {
            path: '/terms-app',
            component: ()=><TermsAndConditions footer={false} hideNavbar={true} />,
        },
        {
            path: '/feedback-app',
            component: ()=><Feedback footer={false}  hideNavbar={true} />,
        },
        {
            path: '/contact-app',
            component: ()=><ContactUs footer={false}  hideNavbar={true} />,
        },
        {
            path: '/privacy-app',
            component: ()=><PrivacyPolicy footer={false}  hideNavbar={true} />,
        },
        {
            path: '/faqs-app',
            component: ()=><FAQs footer={false}  hideNavbar={true} />,
        },

    ]
};






/*
*
*
*
*
* LOGIN - guest
*   HOME - user, admin
*   USERS - admin
*
*
*
* */
