import jwtService from 'app/services/jwtService';
import {setUserData} from './user.actions';
import * as Actions from 'app/store/actions';

export const LOGIN_START = 'LOGIN_START';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export function submitLogin({email, password}) {

    return (dispatch) => {
        dispatch({type: LOGIN_START})
        jwtService.signInWithEmailAndPassword(email, password)
            .then((user) => {
                    dispatch(setUserData(user));

                    return dispatch({
                        type: LOGIN_SUCCESS
                    });
                }
            )
            .catch(error => {
                dispatch(Actions.showMessage({message: error,variant:'error'}));
                return dispatch({
                    type: LOGIN_ERROR,
                    payload: error
                });
            })
    };
}
