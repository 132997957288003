import {
    CREATE_POST_SUCCESS,
    DELETE_POST_SUCCESS, GET_CATEGORIES_SUCCESS,
    GET_POSTS_FAILED,
    GET_POSTS_START,
    GET_POSTS_SUCCESS,
    UPDATE_POST_SUCCESS
} from "../actions";
import _ from 'lodash';

const INITIAL_STATE = {
    postsById: {},
    categoriesById: {},
    posts: {
        ids: [],
        next: [],
        prev: [],
        loading: false
    },
    categories: {
        ids: []
    },
}

const blogReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_POSTS_START:
            return {
                ...state,
                posts: {...state.posts, loading: true}
            };
        case GET_POSTS_SUCCESS:
            console.log(action.payload)
            return {
                ...state,
                postsById: {...state.postsById, ..._.keyBy(action.payload.posts, 'id')},
                posts: {
                    ...state.posts,
                    ids: action.payload.id ? state.posts.ids :  action.payload.search !== '' || action.payload.category !== null ? action.payload.postIds : [...state.posts.ids, ...action.payload.postIds],
                    next: action.payload.id ? state.posts.next : action.payload.next,
                    prev: action.payload.id ? state.posts.prev : action.payload.prev,
                    loading: false
                }
            };
        case GET_POSTS_FAILED:
            return {
                ...state,
                posts: {...state.posts, loading: false}
            };

        case GET_CATEGORIES_SUCCESS:
            return {
                ...state,
                categories: {
                    ...state.categories,
                    ids: action.payload.categoryIds,
                },
                categoriesById: {...state.categoriesById, ..._.keyBy(action.payload.categories, 'id')},
            }

        case UPDATE_POST_SUCCESS:
            return {
                ...state,
                postsById: _.update(state.postsById, action.payload.blog?.id, item=>action.payload.blog)
            }

        case DELETE_POST_SUCCESS:
            return {
                ...state,
                postsById: _.omit(state.postsById, action.payload)
            }


        default:
            return state;
    }
}
export default blogReducer;