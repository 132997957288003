import React, {useState} from 'react';
import {
    Avatar,
    Button,
    Icon,
    ListItemIcon,
    ListItemText,
    Popover,
    MenuItem,
    Typography,
    Divider
} from '@material-ui/core';
import {useSelector, useDispatch} from 'react-redux';
import * as authActions from 'app/auth/store/actions';
import {Link} from 'react-router-dom';

function UserMenu(props)
{
    const dispatch = useDispatch();
    const user = useSelector(({auth}) => auth.user);

    const [userMenu, setUserMenu] = useState(null);

    const userMenuClick = event => {
        setUserMenu(event.currentTarget);
    };

    const userMenuClose = () => {
        setUserMenu(null);
    };

    return (
        <React.Fragment>

            <Button variant={"text"} color={"secondary"} className="h-64 rounded-none" onClick={userMenuClick}>
                <div className="flex flex-col ml-12 items-end">
                    <Typography component="span" className="normal-case font-600 flex pr-5">
                        {user.data.first_name} {user.data.last_name}
                    </Typography>
                    <Typography className="text-11 capitalize text-right pr-5" color="textSecondary">
                        {user.role.toString()}
                    </Typography>
                </div>
                {user.data.photoURL ?
                    (
                        <Avatar className="" alt="user photo" src={user.data.photoURL}/>
                    )
                    :
                    (
                        <Avatar className="">
                            {user.data.first_name[0]}
                        </Avatar>
                    )
                }


                {/*<Icon className="text-16 ml-12 sm:flex" variant="action">keyboard_arrow_down</Icon>*/}
            </Button>
            <Button variant={"text"} color={"secondary"} className="h-64 rounded-none" onClick={() => {
                dispatch(authActions.logoutUser());
                userMenuClose();
            }}>
                <div className="flex flex-col items-start text-gray-700">
                    <Icon>exit_to_app</Icon>
                </div>
            </Button>


            {/*<Popover*/}
            {/*    open={Boolean(userMenu)}*/}
            {/*    anchorEl={userMenu}*/}
            {/*    onClose={userMenuClose}*/}
            {/*    anchorOrigin={{*/}
            {/*        vertical  : 'bottom',*/}
            {/*        horizontal: 'center'*/}
            {/*    }}*/}
            {/*    transformOrigin={{*/}
            {/*        vertical  : 'top',*/}
            {/*        horizontal: 'center'*/}
            {/*    }}*/}
            {/*    classes={{*/}
            {/*        paper: "py-8"*/}
            {/*    }}*/}
            {/*>*/}
            {/*    {!user.role || user.role.length === 0 ? (*/}
            {/*        <React.Fragment>*/}
            {/*            <MenuItem component={Link} to="/login">*/}
            {/*                <ListItemIcon className="min-w-40">*/}
            {/*                    <Icon>lock</Icon>*/}
            {/*                </ListItemIcon>*/}
            {/*                <ListItemText className="pl-0" primary="Login"/>*/}
            {/*            </MenuItem>*/}
            {/*            <MenuItem component={Link} to="/register">*/}
            {/*                <ListItemIcon className="min-w-40">*/}
            {/*                    <Icon>person_add</Icon>*/}
            {/*                </ListItemIcon>*/}
            {/*                <ListItemText className="pl-0" primary="Register"/>*/}
            {/*            </MenuItem>*/}
            {/*        </React.Fragment>*/}
            {/*    ) : (*/}
            {/*        <React.Fragment>*/}
            {/*            /!*<MenuItem component={Link} to="/pages/profile" onClick={userMenuClose}>*!/*/}
            {/*            /!*    <ListItemIcon className="min-w-40">*!/*/}
            {/*            /!*        <Icon>account_circle</Icon>*!/*/}
            {/*            /!*    </ListItemIcon>*!/*/}
            {/*            /!*    <ListItemText className="pl-0" primary="My Profile"/>*!/*/}
            {/*            /!*</MenuItem>*!/*/}
            {/*            /!*<MenuItem component={Link} to="/apps/mail" onClick={userMenuClose}>*!/*/}
            {/*            /!*    <ListItemIcon className="min-w-40">*!/*/}
            {/*            /!*        <Icon>mail</Icon>*!/*/}
            {/*            /!*    </ListItemIcon>*!/*/}
            {/*            /!*    <ListItemText className="pl-0" primary="Inbox"/>*!/*/}
            {/*            /!*</MenuItem>*!/*/}
            {/*            <MenuItem*/}
            {/*                onClick={() => {*/}
            {/*                    dispatch(authActions.logoutUser());*/}
            {/*                    userMenuClose();*/}
            {/*                }}*/}
            {/*            >*/}
            {/*                <ListItemIcon className="min-w-40">*/}
            {/*                    <Icon>exit_to_app</Icon>*/}
            {/*                </ListItemIcon>*/}
            {/*                <ListItemText className="pl-0" primary="Logout"/>*/}
            {/*            </MenuItem>*/}
            {/*        </React.Fragment>*/}
            {/*    )}*/}
            {/*</Popover>*/}
        </React.Fragment>
    );
}

export default UserMenu;
