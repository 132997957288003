import React from 'react';
import authRoles from '../../auth/authRoles'
import Users from "./Users";

export const UsersConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: true
                },
                toolbar: {
                    display: true
                },
                footer: {
                    display: false,
                }
            }
        }
    },
    auth: authRoles.admin,
    routes: [
        {
            path: '/users',
            exact:true,
            component: Users
        },
    ]
};






/*
*
*
*
*
* LOGIN - guest
*   HOME - user, admin
*   USERS - admin
*
*
*
* */
