import React from 'react';
import authRoles from '../../auth/authRoles'
import AskGenerateIDS from "./AskGenerateIDS";

export const AskGenerateIDSConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: true
                },
                toolbar: {
                    display: true
                },
                footer: {
                    display: false,
                }
            }
        }
    },
    auth: authRoles.onlyUser,
    routes: [
        {
            path: '/ask-generate-ids',
            exact:true,
            component: AskGenerateIDS
        },
    ]
};






/*
*
*
*
*
* LOGIN - guest
*   HOME - user, admin
*   USERS - admin
*
*
*
* */
