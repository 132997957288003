export const API_BASE_URL = "https://www.hatchids.com/api/api/v1/"
// export const API_BASE_URL = "http://127.0.0.1:8000/api/v1/"
export const CGI_BIN_URL = "https://www.hatchids.com/cgi-bin/"

export const isNumber = /^\d+$/;
//export const isNumber = /^\w+$/;
export const isNotNumber = /^[\w\S]+$/;

// THESE AMOUNTS JUST FOR DISPLAY, ORIGINAL AMOUNT IS TAKEN BY DJANGO SETTINGS.PY FILE.
export const DEFAULT_IDS_AMOUNT = "25";
export const DEFAULT_IDS_AMOUNT_ADMIN = "40";

export const MAX_REFERENCES_FILES_SIZE = 1024*1024*50; // 50MB

export const CAPTCHA_SITE_KEY = "6LdfNLIbAAAAAKmMeeL2YnRZU5CaOrNvwQfkihOV"

export const ALLOWED_FILETYPES_GENERATE_IDS = ".jpeg,.png,.pdf,.docx,.pptx,.jpg,.doc"

export const MAX_DAYS_FOR_GENERATING_CONSOLIDATED_INVOICE = "180" // in days

export const FEEDBACK_CATEGORIES = [
    'Suggestions',
    'General Queries',
    'Complaint',
    'Sales'
]