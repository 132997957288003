import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {API_BASE_URL} from "../../fuse-configs/constants";
import axios from "axios";
import {FusePageSimple} from "../../../@fuse";
import {
    AppBar,
    Button, CircularProgress, Drawer, Icon, IconButton, MenuItem,
    OutlinedInput,
    Paper, Select,
    Table, TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Toolbar,
    Typography
} from "@material-ui/core";
import GenerateIDS from "../generate-ids/GenerateIDS";

export default function IDSRequests(props) {
    const dispatch = useDispatch();

    const [IDSRequests, setIDSRequests] = useState([]);
    const [selectedIDSRequest, setSelectedIDSRequest] = useState(null);

    const [progress, setProgress] = useState(false);

    const [search, setSearch] = useState('');
    const [searchField, setSearchField] = useState('USER');

    const [next, setNext] = useState(null);
    const [prev, setPrev] = useState(null);

    const [drawer, setDrawer] = useState(false);

    const generateIDS = (idsRequest) => {
        setSelectedIDSRequest(idsRequest);
        setTimeout(() => setDrawer(true), 100)

    }

    const getIDSRequests = (url = null, search = null) => {
        setProgress(true)
        let base_url = API_BASE_URL + 'ids-requests/?limit=100&search_field=' + searchField

        url = url || base_url

        if (search) {
            url = base_url + '&search=' + search
            if(search.slice(-1) === " "){
                url = base_url + '&search=' + search.trim() + '&exact_search=True'
            }

        }

        axios.get(url + `&timestamp=${new Date().getTime()}`)
            .then(res => {
                setIDSRequests(res.data.results)
                setProgress(false)
                setNext(res.data.next);
                setPrev(res.data.previous);
            }).catch(err => {
            setProgress(false)
        })
    }

    const onIDSGenerated = (IDSRequestId) => {
        const tempArray = IDSRequests.filter(item => {
            return IDSRequestId !== item.id
        })
        setIDSRequests(tempArray)
    }

    // Interactions
    const handleSearchFieldUpdate = (event) => {
        setSearchField(event.target.value);
        doSearch('');
    }

    const doSearch = (searchText) => {
        setSearch(searchText);
        setIDSRequests([]);
        setNext(null)
        setPrev(null)
        getIDSRequests(null, searchText);
    }

    useEffect(() => {
            getIDSRequests();
        },
        []
    )

    return <FusePageSimple

        contentToolbar={
            <div className='flex items-center flex-row w-full px-24 h-128 bg-blue-lightest'>
                <Icon color={'secondary'}>assignments</Icon>
                <Typography color={"secondary"} className='ml-8 text-xl flex-1'>IDS Generation Requests</Typography>
                <Select
                    value={searchField}
                    onChange={handleSearchFieldUpdate}
                    variant={"outlined"}
                    className='h-48 text-16 bg-white'
                >
                    <MenuItem value={"USER"}>User</MenuItem>
                    <MenuItem value={"APPLICATION_NUMBER"}>Application</MenuItem>
                    <MenuItem value={"ID"}>Request ID</MenuItem>
                </Select>
                <OutlinedInput className='ml-4 w-full max-w-sm my-4 text-16 bg-white h-48'
                               placeholder="Search"
                               value={search}
                               onChange={(e) => doSearch(e.target.value)}
                />
            </div>
        }

        content=
            {<div>
                <div className='w-full px-24'>
                    <TableContainer component={Paper} className='shadow'>
                        <Table aria-label="a dense table">
                            <TableHead>
                                <TableRow className='font-bold'>
                                    <TableCell className='pl-24'>SN.</TableCell>
                                    <TableCell>#ID</TableCell>
                                    <TableCell align="left">Application Number</TableCell>
                                    <TableCell align="left">User(Requested by)</TableCell>
                                    <TableCell align="left">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {IDSRequests.map((row, index) => (
                                    <TableRow key={row.id}>
                                        <TableCell className='pl-24'>
                                            {index+1}
                                        </TableCell>
                                        <TableCell>
                                            {row.id}
                                        </TableCell>
                                        <TableCell align="left">{row.application_number}</TableCell>
                                        <TableCell>
                                            <Typography>{row.email}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                onClick={() => generateIDS(row)}
                                                color={"secondary"} variant={"contained"} className='rounded-none'>Generate
                                                IDS</Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {
                        progress && (
                            <CircularProgress
                                color={"secondary"}
                                size={24}
                                className='text-center mt-24 block mx-auto'
                            />
                        )
                    }

                    <div className='flex flex-row justify-center p-12 my-12'>
                        <Button
                            onClick={() => prev && getIDSRequests(prev)}
                            variant={"outlined"}
                            size={"small"}
                            color={"primary"}
                            className='rounded-none'
                            disabled={!prev}
                        >
                            Previous
                        </Button>
                        <Button
                            onClick={() => next && getIDSRequests(next)}
                            variant={"outlined"}
                            size={"small"}
                            color={"primary"}
                            className='rounded-none ml-8'
                            disabled={!next}
                        >
                            Next
                        </Button>
                    </div>


                </div>
                {
                    selectedIDSRequest &&
                    <Drawer anchor='right' open={drawer} onClose={() => setDrawer(false)}>
                        <div className='w-2xl'>
                            <AppBar position="static" elevation={0}>
                                <Toolbar className="flex w-full">
                                    <IconButton onClick={() => setDrawer(false)}><Icon
                                        className='text-white opacity-75'>close</Icon></IconButton>
                                    <Typography className='ml-12 text-lg' variant="subtitle1" color="inherit">
                                        Generate IDS for ID #{selectedIDSRequest.id}
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <GenerateIDS IDSRequest={selectedIDSRequest} onIDSGenerated={onIDSGenerated}/>
                        </div>
                    </Drawer>}

            </div>}

    />
}
