import React, {Component} from 'react';
import {withStyles, Card, CardContent, Typography} from '@material-ui/core';
import {FuseAnimate} from '@fuse';
import {Link, withRouter} from 'react-router-dom';
import axios from 'axios';
import LinearProgress from "@material-ui/core/LinearProgress";
import {bindActionCreators} from "redux";
import * as authActions from "../../auth/store/actions";
import {showMessage} from "../../store/actions";
import {connect} from "react-redux";
import {API_BASE_URL} from "../../fuse-configs/constants";
import Header from "../core/components/Header";
import Footer from "../core/components/Footer";
import {logoutUser} from "../../auth/store/actions";

const styles = theme => ({
    root: {
        background: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText
    }
});

class LogoutAll extends Component {

    state = {
        token: null,
        success: false,
        isTokenValid: null,
        label:'Logout from everywhere...'
    };

    componentDidMount() {
        let params = new URLSearchParams(this.props.location.search);
        const token = params.get("token");
        this.setState({
            token: token,
        })
        token && this.validateToken(token);
    }

    validateToken = (token) => {
        this.startLoader()
        axios.get(API_BASE_URL + 'auth/validate-token/', {
            headers: {Authorization: `Bearer ${token}`}
        })
            .then(res => {
                this.setState({isTokenValid: true})
                this.props.logout(token)
            }).catch(err => {
                this.stopLoader()
            this.setState({isTokenValid: false, label:'Invalid token, Can\'t logout'})
        })
    }

    form = React.createRef();

    startLoader = () => {
        this.setState({loading: true})
    }
    stopLoader = () => {
        this.setState({loading: false})
    }


    render() {

        return (
            <div>
                <Header/>
                <div className="pb-320 h-full pt-72 items-center">
                    <FuseAnimate animation={{translateY: [0, '10%']}}>

                        <Card className="w-full max-w-400 mx-auto rounded-12 mt-24 shadow-md hover:shadow-none">
                            {this.state.loader && (
                                <LinearProgress color="primary"/>
                            )}
                            <CardContent className="flex flex-col items-center justify-center p-32 ">
                                <Typography className='p-12 text-center'>{this.state.label}</Typography>

                                <div className="flex flex-col pt-12 justify-start w-full">
                                    <span className="font-medium text-center"><Link to='/login'>Go Back to login</Link></span>
                                </div>

                            </CardContent>
                        </Card>
                    </FuseAnimate>
                </div>
                <Footer/>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        showMessage: showMessage,
        logout:logoutUser
    }, dispatch);
}

export default withStyles(styles, {withTheme: true})(withRouter(connect(null, mapDispatchToProps)(LogoutAll)));
