import React, {useRef} from "react";
import {FusePageSimple} from "../../../@fuse";
import {
    Button, CircularProgress, Dialog,
    Divider,
    Icon,
    Paper,
    Table, TableCell, TableContainer, TableHead, TableRow, TextField,
    Typography
} from "@material-ui/core";
import Dropzone from "react-dropzone";
import JSZip from "jszip";
import {saveAs} from "file-saver";
import {generateZip} from "../../utils";
import {
    ALLOWED_FILETYPES_GENERATE_IDS,
    API_BASE_URL,
    DEFAULT_IDS_AMOUNT_ADMIN,
    isNumber,
    MAX_REFERENCES_FILES_SIZE
} from "../../fuse-configs/constants";
import axios from "axios";
import {useDispatch} from "react-redux";
import {showMessage} from "../../store/actions";
import _ from 'lodash'

function AskGenerateIDS(props) {
    const dispatch = useDispatch();

    const topRef = useRef(null)
    const executeScrollToTop = () => topRef.current.scrollIntoView()


    // user's input IDS data
    const [applicationNumber, setApplicationNumber] = React.useState(props.applicationNumber || '');
    const [patentReferences, setPatentReferences] = React.useState(props.patentReferences || '');
    const [droppedFiles, setDroppedFiles] = React.useState(props?.droppedFiles??[])
    const [NLPReferences, setNLPReferences] = React.useState(props.NLPReferences || [''])

    // success
    const [success, setSuccess] = React.useState(false)

    // dialog & progress
    const [dialog, setDialog] = React.useState(false);
    const [progress, setProgress] = React.useState(false);

    // handle interactions * * * * * * * * * * * * * * * * * * * * * * * * * * * *

    const handleApplicationNumber = (applicationNumber) => {
        const temp = applicationNumber.replace('/','').replace(',','')
        if (isNumber.test(temp) || temp === "") {
            setApplicationNumber(applicationNumber);
        }

    }
    const handlePatentReferences = (patentReferences) => {
        setPatentReferences(patentReferences.replaceAll(/\s/g, ''));
    }

    const handleFilesDrop = (files) => {
        console.log(files)
        setDroppedFiles(_.union(droppedFiles??[], files))

    }
    const handleFileRemove = (target_index) => {
        setDroppedFiles(droppedFiles.filter((file, index) => index !== target_index));
    }

    const handleAddNLPReference = () => {
        setNLPReferences(NLPReferences.concat(['']));
    }
    const handleRemoveNLPReference = (target_index) => {
        // if only one text then remove text only
        if (NLPReferences.length === 1) {
            handleEditNLPReference(0, '');
            return
        }
        setNLPReferences(NLPReferences.filter((text, index) => index !== target_index));
    }
    const handleEditNLPReference = (target_index, current_text) => {
        setNLPReferences(NLPReferences.map((text, index) => {
            if (index === target_index) {
                return current_text;
            }
            return text
        }))

    }

    console.log(droppedFiles.length)

    const handleConfirmGenerateIDS = async () => {
        const uploaded_zip_file = await generateZip(droppedFiles, "UPLOADED_FILE.zip");

        // TOTAL FILE SIZE MUST BE LESS THAN 50
        if(uploaded_zip_file && uploaded_zip_file.size>MAX_REFERENCES_FILES_SIZE){
            dispatch(showMessage({
                message: "Uploaded files total size can't exceed 50MB",
                variant: 'error',
            }))
            return
        }


        setDialog(true);
    }

    const handlePricingResponse = (response) => {
        if (response) {
            submitGenerateIDSRequest();
        }
        setDialog(false);
    }

    // --- handle interactions * * * * * * * * * * * * * * * * * * * * * * * * * * *


    // API CALLS * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
    const submitGenerateIDSRequest = async () => {
        setProgress(true)

        // lets generate zip of uploaded files.
        const uploaded_zip_file = await generateZip(droppedFiles, "UPLOADED_FILE.zip");

        let formData = new FormData();

        formData.append('application_number', applicationNumber.replace('/','').replace(',',''))
        patentReferences && formData.append('patent_references', patentReferences);
        uploaded_zip_file && formData.append('files', uploaded_zip_file)


        for (let i = 0; i < NLPReferences.length; i++) {
            formData.append('nlp_references[]', NLPReferences[i])
        }
        axios.post(API_BASE_URL + 'ids-requests/', formData, {
            headers: {'content-type': 'multipart/form-data'}
        })
            .then(res => {
                dispatch(showMessage({
                    message: "IDS Generation Requested successfully",
                    variant: 'success',
                }))
                setProgress(false)
                executeScrollToTop()
                setSuccess(true)
            })
            .catch(err => {
                setProgress(false)
                dispatch(showMessage({
                    message: "IDS Generation Request Failed.",
                    variant: 'error',
                }))
            })
    }

    // --- API CALLS * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

    // EXTRA

    React.useEffect(() => {
    }, [])


    return (
        <FusePageSimple

            contentToolbar={<div ref={topRef} className='w-full'>
                <div className='flex flex-row items-center px-24 py-16 w-full'>
                    <Icon color={'secondary'}>crop_landscape</Icon>
                    <Typography color={"secondary"} className='ml-8 text-xl'>Ask HatchIDS Team to generate new IDS</Typography>
                </div>
                <Divider/>
            </div>}

            content={
                <div className='px-24 pb-64 w-full'>


                    {!success && (
                        <div className='mt-12 max-w-xl'>
                            <TextField
                                label="Application Number"
                                placeholder="Enter application number"
                                value={applicationNumber}
                                onChange={e => handleApplicationNumber(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                autoFocus
                                variant="outlined"
                                className='mt-12 font-bold w-full max-w-md bg-white'
                            />

                            <TextField
                                label="Patent References"
                                placeholder="Enter patent references, comma separated"
                                multiline
                                value={patentReferences}
                                onChange={e => handlePatentReferences(e.target.value)}
                                rowsMax={3}
                                rows={3}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                className='mt-12 font-bold w-full max-w-md bg-white'
                                hidden={success}
                            />
                            <Dropzone

                                onDropRejected={() => window.alert("Something went wrong")}
                                onDrop={handleFilesDrop}
                                accept={ALLOWED_FILETYPES_GENERATE_IDS}
                            >
                                {({getRootProps, getInputProps}) => (
                                    <section
                                        className='border border-dashed rounded-lg h-128 border-gray-700 bg-gray-100 max-w-md mt-12'>
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <p className='p-8 text-center align-middle py-48'>Drag 'n' drop file here,
                                                or
                                                click to
                                                select files</p>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                            <TableContainer component={Paper} hidden={droppedFiles.length <= 0}
                                            className='rounded-none my-12 shadow max-w-md'>
                                <Table size={"small"} aria-label="a table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>S.No.</TableCell>
                                            <TableCell>Filename</TableCell>
                                            <TableCell>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {
                                        droppedFiles?.map((file, index) => (
                                            <TableRow>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>{file.name}</TableCell>
                                                <TableCell><Icon onClick={() => handleFileRemove(index)}
                                                                 className='text-red-light'>delete</Icon></TableCell>
                                            </TableRow>
                                        ))
                                    }

                                </Table>
                            </TableContainer>

                        </div>
                    )}

                    {!success && NLPReferences.map((text, index) => (
                        <div className='flex flex-row items-center'>
                            <TextField
                                placeholder="Add Author/Title/Date of NPL References"
                                multiline
                                rowsMax={3}
                                rows={2}
                                value={text}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={e => handleEditNLPReference(index, e.target.value)}
                                variant="outlined"
                                className='mt-12 font-bold w-full max-w-md bg-white'
                            />
                            {
                                text !== ''
                                &&
                                <Icon
                                    onClick={() => handleRemoveNLPReference(index)}
                                    className='text-red-light'>delete</Icon>
                            }
                        </div>
                    ))

                    }
                    <Button
                        hidden={success}
                        disabled={NLPReferences[NLPReferences.length - 1] === ''}
                        variant='outlined' className='rounded-none font-bold my-8'
                        onClick={handleAddNLPReference}
                    >+ Add more</Button>


                    <div className='pb-24'>
                        <TableContainer component={Paper} className='rounded-none my-12 shadow max-w-lg'>
                            <Table aria-label="a table">
                                <TableRow>
                                    <TableCell colSpan={2} className=''>
                                        {success ? <Typography className='text-green-light font-light text-xl'>
                                            IDS generation requested successfully 🎉
                                        </Typography>
                                        :
                                            <Typography className='italic text-lg font-light'>Confirm Details</Typography>}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className='font-bold'>Application Number</TableCell>
                                    <TableCell colSpan={2}>{applicationNumber !== '' ? applicationNumber : <span
                                        className='text-red-light italic'>Application number is required*</span>}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className='font-bold'>Patent References</TableCell>
                                    <TableCell align="left">{patentReferences}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className='font-bold'>Files</TableCell>
                                    <TableCell align="left">
                                        {droppedFiles && droppedFiles.map(file => file.name + ", ")}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className='font-bold'>NPL References</TableCell>
                                    <TableCell align="left">
                                        {NLPReferences.map(text => (<Typography>{text}</Typography>))}
                                    </TableCell>
                                </TableRow>
                            </Table>
                        </TableContainer>
                    </div>
                    {!success && (
                        <Button color={"secondary"} variant={"contained"}
                                onClick={handleConfirmGenerateIDS}
                                disabled={applicationNumber === ''}
                        >{progress ?
                            (<>Requesting <CircularProgress size={16} className='ml-12'/></>)
                            : "confirm - Ask Us To Generate IDS"}

                        </Button>
                    )}


                    {/* eslint-disable-next-line react/jsx-no-undef */}

                    <Dialog maxWidth={"md"}
                            open={dialog}
                            onClose={() => setDialog(false)}
                    >
                        <div className='p-24'>
                            <Typography className='text-lg font-light'>This action costs an amount. You need to pay for
                                this.</Typography>
                            <Typography className='text-xl my-12 font-light text-green-light'>{DEFAULT_IDS_AMOUNT_ADMIN}$</Typography>
                            <div className='flex justify-end'>
                                <Button className='rounded-none' variant={"text"} color={"secondary"}
                                        onClick={() => handlePricingResponse(false)}
                                >Cancel</Button>
                                <Button className='rounded-none ml-12' variant={"contained"} color={"secondary"}
                                        onClick={() => handlePricingResponse(true)}
                                >Agree & Continue</Button>
                            </div>

                        </div>
                    </Dialog>

                </div>
            }

        />
    )
}

export default AskGenerateIDS;
