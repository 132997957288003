import * as Actions from '../actions';
import {REGISTER_START} from "../actions";

const initialState = {
    success: false,
    error: {
        username: null,
        password: null,
        loading: false,
    }
};

const register = function (state = initialState, action) {
    switch (action.type) {
        case Actions.REGISTER_START:{
            return {
                ...initialState,
                loading: true
            };
        }
        case Actions.REGISTER_SUCCESS: {
            return {
                ...initialState,
                success: true,
                loading: false,
            };
        }
        case Actions.REGISTER_ERROR: {
            return {
                success: false,
                loading: false,
                error: action.payload
            };
        }
        default: {
            return state
        }
    }
};

export default register;
