import React from 'react';
import authRoles from '../../auth/authRoles'
import Login from "./Login";
import Signup from "./Signup";
import ResetPassword from "./ResetPassword";
import VerifyEmail from "./VerifyEmail";
import LogoutAll from "./LogoutAll";

export const AuthConfig = {
    settings:{
        layout:{
            config:{
                navbar:{
                    display:false
                },
                toolbar:{
                    display:false
                },
                footer:{
                    display:false
                }
            }

        }
    },
    auth    : authRoles.onlyGuest,
    routes:[
        {
            path:'/login',
            component:Login
        },
        {
            path:'/signup',
            component: Signup
        },
        {
            path:'/reset-password',
            component: ResetPassword
        },
        {
            path:'/verify-email',
            component: VerifyEmail
        },
        {
            path:'/logout-all',
            component: LogoutAll
        },
    ]
};
