import React from "react";
import Header from "./components/Header";
import {Paper} from "@material-ui/core";

import Helmet from "react-helmet";

class ContactUs extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        page: null
    };

    componentDidMount() {
        fetch("static_html_files/contact_us.html")
            .then(result => {
                return result.text();
            })
            .then(page => {
                this.setState(
                    {
                        page: {__html: page}
                    }
                );
            });
    }

    render() {
        const {page} = this.state;
        return (
            <>
                <Header {...this.props}/>
                <Helmet>
                    <title> HatchIDS Contact </title>
                    <meta name="description" content="Contact HatchIDS via website web form and see our domestic as well as international locations here." />
                    <link rel="canonical" href="https://www.hatchids.com/contact-us" />
                </Helmet>
                {this.props.hideNavbar ?
                    <Paper className="pb-24 max-w-lg mx-auto my-24 items-center shadow hover:shadow-none">
                        <div
                            dangerouslySetInnerHTML={page && page}
                        />
                    </Paper>
                    :
                    <Paper className="pb-24 max-w-2xl mx-auto my-24 items-center shadow hover:shadow-none">
                        <div
                            dangerouslySetInnerHTML={page && page}
                        />
                    </Paper>

                }
            </>
        );
    }
}

export default ContactUs;

