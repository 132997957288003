import React from 'react';
import BlogList from './BlogList';
import {authRoles} from "../../auth";

const BlogConfigAdmin = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	auth:authRoles.admin,
	routes: [
		{
			path: '/blog/create/',
			component: React.lazy(() => import('./BlogCreate')),
			exact:true
		},
		{
			path: '/blog/update/:id/',
			component: React.lazy(() => import('./BlogCreate')),
			exact:true
		},
	]
};

export default BlogConfigAdmin;

/**
 * Lazy load Example
 */
/*
import React from 'react';

const ExampleConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/example',
            component: React.lazy(() => import('./Example'))
        }
    ]
};

export default ExampleConfig;

*/
