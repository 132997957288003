import * as Actions from 'app/store/actions/IDS';
import _ from 'lodash';

const INITIAL_STATE = {
    application: {
        applicationNumber:'',
        application: null,
        label: null,
        loading: false,
    },
    reference:{
        patentReferences:'',
        patents:null,
        label:null,
        loading:false,
    },
}

const generateIDS = function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case Actions.GET_APPLICATION_START:
            return {
                ...state,
                application: {
                    ...state.application,
                    label: action.payload.msg,
                    loading: true,
                    application: null,
                    applicationNumber:action.payload.applicationNumber
                }
            }
        case Actions.GET_APPLICATION_SUCCESS:
            return {
                ...state,
                application: {
                    ...state.application,
                    label: action.payload.msg,
                    loading: false,
                    application: action.payload.application,
                }
            }
        case Actions.GET_APPLICATION_FAILED:
            return {
                ...state,
                application: {
                    ...state.application,
                    loading: false,
                    label: action.payload.msg,
                    application: null
                }
            }

        case Actions.GET_PATENTS_START:
            return {
                ...state,
                reference: {
                    ...state.reference,
                    patents: null,
                    label: action.payload.msg,
                    loading: true,
                    patentReferences:action.payload.patentReferences
                }
            }

        case Actions.GET_PATENTS_SUCCESS:
            const ts =  {
                ...state,
                reference: {
                    ...state.reference,
                    patents: state.reference.patents ?
                        (action.payload.patent ? _.unionBy(state.reference.patents, [action.payload.patent], 'docNum') : state.reference.patents)
                        :
                        [action.payload.patent],
                    label: action.payload.msg,
                    loading: false
                }
            }
            return ts;

        case Actions.GET_PATENTS_FAILED:
            return {
                ...state,
                reference: {
                    ...state.reference,
                    patents: null,
                    label: action.payload.msg,
                    loading: false
                }
            }

        case Actions.RESET_IDS_GENERATE_FORM:
            return INITIAL_STATE;

        default:
            return state
    }
}

export default generateIDS;
