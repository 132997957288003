import {API_BASE_URL, isNumber , isNotNumber} from "../../fuse-configs/constants";
import history from "@history";

import axios from "axios";
import _ from 'lodash';
import {SHOW_MESSAGE, showMessage} from "./fuse";

import { writeFileSync,appendFile} from 'fs';

export const GET_POSTS_START = 'BLOG GET POSTS START';
export const GET_POSTS_SUCCESS = 'BLOG GET POSTS SUCCESS';
export const GET_POSTS_FAILED = 'BLOG GET POSTS FAILED';

export const GET_CATEGORIES_START = 'BLOG GET CATEGORIES START';
export const GET_CATEGORIES_SUCCESS = 'BLOG GET CATEGORIES SUCCESS';
export const GET_CATEGORIES_FAILED = 'BLOG GET CATEGORIES FAILED';

export const CREATE_POST_START = 'BLOG CREATE POST START';
export const CREATE_POST_SUCCESS = 'BLOG CREATE POST SUCCESS';
export const CREATE_POST_FAILED = 'BLOG CREATE POST FAILED';

export const UPDATE_POST_START = 'BLOG UPDATE POST START';
export const UPDATE_POST_SUCCESS = 'BLOG UPDATE POST SUCCESS';
export const UPDATE_POST_FAILED = 'BLOG UPDATE POST FAILED';

export const DELETE_POST_START = 'BLOG DELETE POST START';
export const DELETE_POST_SUCCESS = 'BLOG DELETE POST SUCCESS';
export const DELETE_POST_FAILED = 'BLOG DELETE POST FAILED';

/*
* const ids = _.flatMap(ARRAY, ITEM => ITEM.id)
* postsById: {...state.postsById, ..._.keyBy(action.payload, 'id')}
*
* DELETING
* _.omit(OBJECT, KEY)
*
* UNION
* _.union(ARRAY_1, ARRAY_2)
*
* */

export const getPosts = ({search = '', category = null, id = null, next_url = null}) => {
    return dispatch => {
        //alert('Check')
        
        //
        dispatch({
            type: GET_POSTS_START
        })
        console.log(id, 'id')
        let url = API_BASE_URL + 'blogs/';
        if (isNumber.test(id)) {
            url = url + id + '/'
        }
        // BIG CHANGE //
        if (isNotNumber.test(id) && id!==null){
          console.log('IN THE TITLE >>>>>------')
          url = url + id + '/'
        }
        let params = {ordering: '-date_created', limit: 20, timestamp: new Date().getTime()}
        if (search !== '') {
            params.search = search
        }
        if (category) {
            params.category = category
        }
        console.log(params, next_url, url)
        axios.get(next_url || url, {
            params, headers: {
                'Authorization': null
            }
        }).then(res => {
            console.log(res)
            const postIds = id ? [id] : _.flatMap(res.data.results, item => item.id)
            console.log(postIds, 'postIds')
            const posts = id ? [res.data] : res.data.results
            console.log(posts,'POSTS')
            const prev = id ? null : res.data.prev
            const next = id ? null : res.data.next

            return dispatch({
                type: GET_POSTS_SUCCESS,
                payload: {
                    id,
                    posts,
                    postIds,
                    next,
                    prev,
                    search,
                    category
                }
            })
        }).catch(err => {
            console.log(err);
            return dispatch({
                type: GET_POSTS_FAILED
            })
        })
    }
}


export const getCategories = () => {
    return dispatch => {
        dispatch({
            type: GET_CATEGORIES_START
        })
        let params = {timestamp: new Date().getTime()}

        axios.get(API_BASE_URL + 'categories/', {
            params, headers: {
                'Authorization': null
            }
        })
            .then(res => {
                const categoryIds = _.flatMap(res.data.results, item => item.id)
                const categories = res.data.results;

                return dispatch({
                    type: GET_CATEGORIES_SUCCESS,
                    payload: {
                        categoryIds,
                        categories
                    }
                })
            }).catch(err => {
            console.log(err)
            return dispatch({
                type: GET_CATEGORIES_FAILED
            })
        })
    }
}

export const updateBlog = (blog) => {
    return dispatch => {
        if (!blog) {
            return dispatch({
                type: UPDATE_POST_FAILED
            })
        }
        dispatch({
            type: UPDATE_POST_START
        })
        const formData = new FormData()
        formData.append('title', blog.title)
        formData.append('content', blog.content)
        formData.append('meta',blog.meta)
        formData.append('id',blog.id)
        
        if (blog.category) {
            formData.append('category', blog.category)
        }
        axios.put(API_BASE_URL + 'blogs/' + blog.id + '/', formData)
            .then(res => {
                dispatch({
                    type: SHOW_MESSAGE,
                    options: {
                        message: 'Blog updated successfully',
                        variant: 'success'
                    }
                })

                return dispatch({
                    type: UPDATE_POST_SUCCESS,
                    payload: blog
                })
            }).catch(err => {
            console.log(err);
            dispatch({
                type: SHOW_MESSAGE,
                options: {
                    message: 'Blog updation failed',
                    variant: 'error'
                }
            })

            return dispatch({
                type: UPDATE_POST_FAILED
            })
        })
    }
}

export const createBlog = (blog) => {
    return dispatch => {
        dispatch({
            type: CREATE_POST_START
        })
        const formData = new FormData()
        formData.append('title', blog.title)
        formData.append('content', blog.content)
        formData.append('meta',blog.meta)
        formData.append('id',blog.title+'id')
        console.log('CHECK THIS')
        console.log(blog.meta)
        if (blog.category) {
            formData.append('category', blog.category)
        }
        axios.post(API_BASE_URL + 'blogs/', formData)
            .then(res => {
                const postIds = [res.data.id]
                const posts = [res.data]
                const prev = null
                const next = null
                dispatch({
                    type: GET_POSTS_SUCCESS,
                    payload: {
                        id: res.data.id,
                        posts,
                        postIds,
                        next,
                        prev
                    }
                })
                dispatch({
                    type: SHOW_MESSAGE,
                    options: {
                        message: 'Blog created successfully',
                        variant: 'success'
                    }
                })
                history.push('/blog/' + res.data.id)
                return dispatch({
                    type: CREATE_POST_SUCCESS,
                    payload: blog
                })
            }).catch(err => {
            console.log(err);
            dispatch({
                type: SHOW_MESSAGE,
                options: {
                    message: 'Blog creation failed',
                    variant: 'error'
                }
            })

            return dispatch({
                type: CREATE_POST_FAILED
            })
        })
    }
}

export const deleteBlog = (id) => {
    return dispatch => {
        dispatch({
            type: DELETE_POST_START
        })
        axios.delete(API_BASE_URL + 'blogs/' + id + '/')
            .then(() => {
                dispatch({
                    type: SHOW_MESSAGE,
                    options: {
                        message: 'Blog deleted successfully',
                        variant: 'success'
                    }
                })

                return dispatch({
                    type: DELETE_POST_SUCCESS,
                    payload: id
                })
            }).catch(err => {
            console.log(err);
            dispatch({
                type: SHOW_MESSAGE,
                options: {
                    message: 'Blog deletion failed',
                    variant: 'error'
                }
            })

            return dispatch({
                type: DELETE_POST_FAILED
            })
        })
    }
}
