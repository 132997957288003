import React from "react";
import Header from "./components/Header";
import {Button, Paper, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import ReactPlayer from "react-player";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from 'react-responsive-carousel';

import Helmet from 'react-helmet'
class Landing extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {role} = this.props;
        return (
            <div>
                <Header/>
                <Helmet>
                    <title>Streamline SB08 Form Submission with HatchIDS</title>
                    <meta name="description" content="Experience seamless SB08 form submissions with HatchIDS, the innovative tool that swiftly generates both Patent & Non-Patent References for your USPTO filings"/>
                    <link rel="canonical" href="https://www.hatchids.com/"/>
                </Helmet>
                <div className="pb-64 items-center">
                    <div>
                            <Carousel
                                interval={6000}
                                useKeyboardArrows
                                infiniteLoop autoPlay showStatus={false} showThumbs={false}>
                                <div className='w-full h-400 flex flex-col justify-center bg-blue-dark'>
                                    <img src='static/slider_images/1.jpeg' className='opacity-25'/>
                                    <Typography className='text-4xl text-white p-8 w-full absolute font-light'>HatchIDS is a fully
                                        automated software for seamlessly and efficiently generating Information
                                        Disclosure Statements (IDSs)</Typography>
                                </div>
                                <div className='w-full h-400 flex flex-col justify-center bg-black'>
                                    <img src='static/slider_images/2.jpeg' className='opacity-25'/>
                                    <Typography className='text-4xl text-white p-8 w-full absolute font-light'>HatchIDS
                                        automatically generates SB08 forms along with the Patent and Non-Patent
                                        References to enable quick submissions at the USPTO.</Typography>
                                </div>
                                <div className='w-full h-400 flex flex-col justify-center bg-blue-dark'>
                                    <img src='static/slider_images/3.jpeg' className='opacity-25'/>
                                    <Typography className='text-4xl text-white p-8 w-full absolute font-light'> HatchIDS allows
                                        download of multiple Patent References in a single go so that the
                                        SB08 as well as the Prior Art references can be downloaded and filed in a
                                        single submission.</Typography>
                                </div>
                                <div className='w-full h-400 flex flex-col justify-center bg-black'>
                                    <img src='static/slider_images/4.jpeg' className='opacity-25'/>
                                    <Typography className='text-4xl text-white p-8 w-full absolute font-light'>HatchIDS, through a
                                        smooth and intuitive interface, allows clients to create accounts and save all
                                        their previously generated IDSs/copies of prior art references to allow them to
                                        be accessed/evaluated/re-filed for family applications at a future
                                        date.</Typography>
                                </div>
                                <div className='w-full h-400 flex flex-col justify-center bg-blue-dark'>
                                    <img src='static/slider_images/5.jpeg' className='opacity-25'/>
                                    <Typography className='text-4xl text-white p-8 w-full absolute font-light'>HatchIDS also allows
                                        a smooth billing process where invoices are raised on a monthly basis and can be
                                        paid on a post-dated basis without the requirement of sharing credit card
                                        details.</Typography>
                                </div>
                                <div className='w-full h-400 flex flex-col justify-center bg-black'>
                                    <img src='static/slider_images/6.jpeg' className='opacity-25'/>
                                    <Typography className='text-4xl text-white p-8 w-full absolute font-light'> Finally, HatchIDS
                                        also allows clients/customers to ask IIPRD to generate the IDSs and simply share
                                        the request with IIPRD through the interface along with submitting the US Patent
                                        Application Number and the Prior Art References.</Typography>
                                </div>
                            </Carousel>
                        <div className='max-w-xl flex flex-row justify-center w-full block mx-auto'>

                            <Typography className='text-2xl font-light text-center my-14 p-24'>HatchIDS – A leading
                                Automated IDS (SB08) Generation Software for USPTO Applicants/Law Firms</Typography>

                        </div>

                        <div className='max-w-md flex flex-row justify-center w-full block mx-auto'>

                            <ReactPlayer
                                width={'100%'}
                                playing='true'
                                controls='true'
                                style={{backgroundColor: "#00000000"}}
                                url='/static/Introduction.mp4'
                            />
                        </div>

                        <div className='max-w-xl flex flex-row justify-center w-full block mx-auto'>
                            <Typography className='text-2xl font-light text-center my-24 p-24'></Typography>
                        </div>


                        {/*<div dangerouslySetInnerHTML={template} />*/}

                    </div>
                </div>
            </div>
        );
    }

}

function mapStateToProps({auth}) {
    return {
        role: auth.user.role
    }
}

export default connect(mapStateToProps)(Landing);
