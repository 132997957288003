import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button, Paper, Typography} from "@material-ui/core";
import Formsy from "formsy-react";
import {TextFieldFormsy} from "../../../@fuse";
import axios from "axios";
import {API_BASE_URL} from "../../fuse-configs/constants";
import {setUserData} from "../../auth/store/actions";
import {showMessage} from "../../store/actions";

export default function AccountSettings() {
    const user = useSelector(({auth}) => auth.user);
    const [canSubmit, setCanSubmit] = useState(false);
    const dispatch = useDispatch();

    const onSubmit = (data) => {
        // call API + update local.

        axios.post(API_BASE_URL + 'auth/update/', data)
            .then(res => {
                dispatch(setUserData(res.data));
                dispatch(showMessage({
                    message: "Account updated!",
                    variant: 'success'
                }))
                localStorage.setItem('jwt_access_token', res.data.token);
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.token;
            }).catch(err => {
            dispatch(showMessage({
                message: (err.response && err.response.data && err.response.data.msg) || "Account update failed",
                variant: 'error'
            }))
        })

    }

    const disableButton = () => {
        setCanSubmit(false)
    };

    const enableButton = () => {
        setCanSubmit(true)
    };

    return (
        <Paper className='m-12 p-12 block max-w-sm rounded-none shadow-0 border border-gray-400'>
            <Typography className='pb-12 text-gray-700 italic'>{user.data.email}</Typography>
            <Formsy
                onValidSubmit={onSubmit}
                onValid={enableButton}
                onInvalid={disableButton}
                className="flex flex-col justify-center w-full"
            >
                <div className='flex flex-row'>
                    <TextFieldFormsy
                        className="mb-16 flex-1"
                        type="text"
                        name="first_name"
                        label="First Name"
                        value={user.data.first_name}
                        validations={{
                            isAlpha: true,
                        }}
                        validationErrors={{
                            isAlpha: 'Please enter a valid name',
                        }}
                        InputProps={{
                            autocomplete: "off"
                        }}
                        variant="outlined"
                        required

                    />
                    <TextFieldFormsy
                        className="mb-16 ml-5 flex-1"
                        type="text"
                        name="last_name"
                        label="Last Name"
                        value={user.data.last_name}
                        validations={{
                            isAlpha: true,
                        }}
                        validationErrors={{
                            isAlpha: 'Please enter a valid name',
                        }}
                        InputProps={{
                            autocomplete: "off"
                        }}
                        variant="outlined"
                        required

                    />

                </div>
                <TextFieldFormsy
                    className="mb-16"
                    type="text"
                    name="mobile"
                    label="Mobile No."
                    value={user.data.mobile}
                    validations={{
                        isNumeric: true,
                        minLength: 10,
                    }}
                    validationErrors={{
                        isNumeric: "Please enter a valid mobile",
                        minLength: 'Min character length is 10',
                    }}
                    InputProps={{
                        autocomplete: "off"
                    }}
                    variant="outlined"

                />
                <TextFieldFormsy
                    className="mb-16"
                    type="text"
                    name="organization"
                    label="Organization"
                    value={user.data.organization}
                    InputProps={{
                        autocomplete: "off"
                    }}
                    variant="outlined"
                    required

                />

                <TextFieldFormsy
                    className="mb-16"
                    type="text"
                    name="street"
                    label="Billing Address"
                    rows={2}
                    multiline
                    value={user.data.street}
                    validations={{
                        minLength: 8
                    }}
                    validationErrors={{
                        minLength: 'Min character length is 8'
                    }}
                    required
                    variant="outlined"
                />
                <TextFieldFormsy
                    className="mb-16"
                    type="text"
                    name="city"
                    label="City"
                    value={user.data.city}
                    validations={{
                        minLength: 3
                    }}
                    validationErrors={{
                        minLength: 'Min character length is 3'
                    }}
                    required
                    variant="outlined"
                />
                <TextFieldFormsy
                    className="mb-16"
                    type="text"
                    name="pincode"
                    label="Pincode"
                    value={user.data.pincode}
                    validations={{
                        minLength: 6
                    }}
                    validationErrors={{
                        minLength: 'Min character length is 6'
                    }}
                    required
                    variant="outlined"
                />
                <TextFieldFormsy
                    className="mb-16"
                    type="text"
                    name="state"
                    label="State"
                    value={user.data.state}
                    validations={{
                        minLength: 3
                    }}
                    validationErrors={{
                        minLength: 'Min character length is 3'
                    }}
                    required
                    variant="outlined"
                />
                <TextFieldFormsy
                    className="mb-16"
                    type="text"
                    name="country"
                    label="Country"
                    value={user.data.country}
                    validations={{
                        minLength: 3
                    }}
                    validationErrors={{
                        minLength: 'Min character length is 3'
                    }}
                    required
                    variant="outlined"
                />


                <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    className="w-full mx-auto mt-12 normal-case"
                    aria-label="Update"
                    disabled={!(canSubmit)}
                    value="legacy"
                >
                    Update
                </Button>

            </Formsy>

        </Paper>

    )
}
