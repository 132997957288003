import React, {useEffect, useRef, useState} from "react";
import {FusePageSimple} from "../../../@fuse";
import axios from "axios";
import {
    AppBar,
    Button, Checkbox, CircularProgress, Dialog,
    Divider, Drawer, Icon, IconButton, LinearProgress,
    Paper,
    Step,
    StepContent,
    StepLabel,
    Stepper, Table, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Tooltip,
    Typography
} from "@material-ui/core";
import Dropzone from "react-dropzone";
import {
    ALLOWED_FILETYPES_GENERATE_IDS,
    API_BASE_URL,
    DEFAULT_IDS_AMOUNT,
    isNumber,
    MAX_REFERENCES_FILES_SIZE
} from "../../fuse-configs/constants";
import {generateZip, readZIP} from "../../utils";
import {useDispatch, useSelector} from "react-redux";
import {getApplication, getPatents, getSB08file, resetIDSGenerateForm, showMessage} from "../../store/actions";
import IDSDetail from "../ids/IDSDetail";
import {Link, Redirect} from "react-router-dom";
import AskGenerateIDS from "../ask-generate-ids/AskGenerateIDS";

function getSteps() {
    return [
        'Add Application Number(*)',
        'Patent references (optional)',
        'Add Author/Title/Date of NPL References (optional)',
        'Drag n Drop Desired Non-Patent References and Related References (optional)',
        'Confirm details & Generate IDS',
    ];
}

function GenerateIDS(props) {

    const user = useSelector(({auth}) => auth.user);
    const application = useSelector(({IDS}) => IDS.generateIDS.application);
    const reference = useSelector(({IDS}) => IDS.generateIDS.reference);
    const dispatch = useDispatch();

    const topRef = useRef(null)

    const executeScrollToTop = () => topRef.current.scrollIntoView()


    // ids & dialog
    const [IDS, setIDS] = React.useState();
    const [dialog, setDialog] = React.useState(false);


    const [activeStep, setActiveStep] = React.useState(0);

    // user's input IDS data
    const [applicationNumber, setApplicationNumber] = React.useState('');
    const [patentReferences, setPatentReferences] = React.useState('');
    const [droppedFiles, setDroppedFiles] = React.useState([])
    const [NLPReferences, setNLPReferences] = React.useState([''])
    const [NLPCheckboxes, setNLPCheckboxes] = React.useState(['NO'])

    const [IDSProgress, setIDSProgress] = React.useState(false);
    const [filesDownloadingProgress, setFilesDownloadingProgress] = React.useState(false);

    const [drawer, setDrawer] = useState(false);


    // handle interactions  * * * * * * * * * * * * * * * * * * * * * * * * * * * *

    const goNext = () => {
        goToStep(activeStep + 1)
    }

    const goToStep = (step) => {

        if (activeStep === 0) {
            // search application
            if (applicationNumber === "" || (application.label === null && application.application === null)) {
                dispatch(getApplication(applicationNumber.replace('/', '').replace(',', '')))
            } else if (application.applicationNumber !== applicationNumber.replace('/', '').replace(',', '')) {
                dispatch(getApplication(applicationNumber.replace('/', '').replace(',', '')))
            } else {
                setActiveStep(activeStep + 1);
            }
        } else if (activeStep === 1 && reference.patentReferences !== patentReferences) {
            dispatch(getPatents(patentReferences))
        } else {
            activeStep === step ? setActiveStep(5) : setActiveStep(step)
        }

        setTimeout(() => executeScrollToTop(), 400)


    }

    const handleApplicationNumber = (applicationNumber) => {
        // in case of IDS request. don't change the application_number
        if (props.IDSRequest) {
            return
        }

        let temp = applicationNumber.replace('/', '').replace(',', '')

        if (isNumber.test(temp) || temp === "") {
            setApplicationNumber(applicationNumber);
        }
    }
    const handlePatentReferences = (patentReferences) => {
        setPatentReferences(patentReferences.replaceAll(/\s/g, ''));
    }

    const handleFilesDrop = (files) => {
        setDroppedFiles(droppedFiles.concat(files))
    }
    const handleFileRemove = (target_index) => {
        setDroppedFiles(droppedFiles.filter((file, index) => index !== target_index));
    }

    const handleAddNLPReference = () => {
        setNLPReferences(NLPReferences.concat(['']));
        setNLPCheckboxes(NLPCheckboxes.concat(['NO']))
    }
    const handleRemoveNLPReference = (target_index) => {
        // if only one text then remove text only
        if (NLPReferences.length === 1) {
            handleEditNLPReference(0, '');
            return
        }
        setNLPReferences(NLPReferences.filter((text, index) => index !== target_index))
        setNLPCheckboxes(NLPCheckboxes.filter((text, index) => index !== target_index));
    }
    const handleEditNLPReference = (target_index, current_text) => {
        setNLPReferences(NLPReferences.map((text, index) => {
            if (index === target_index) {
                return current_text;
            }
            return text
        }))

    }

    const handleCheckNLPReferenceChange = (target_index, checked) => {
        if (checked) {
            setNLPCheckboxes(NLPCheckboxes.map((text, index) => {
                if (index === target_index) {
                    return "YES";
                }
                return text
            }))
        } else {
            setNLPCheckboxes(NLPCheckboxes.map((text, index) => {
                if (index === target_index) {
                    return "NO";
                }
                return text
            }))
        }

    }


    const handleConfirmGenerateIDS = async () => {
        const uploaded_zip_file = await generateZip(droppedFiles, "UPLOADED_FILE.zip");
        // console.log(uploaded_zip_file.size/1024)

        // TOTAL FILE SIZE MUST BE LESS THAN 50
        if (uploaded_zip_file && uploaded_zip_file.size > MAX_REFERENCES_FILES_SIZE) {
            dispatch(showMessage({
                message: "Uploaded files total size can't exceed 50MB",
                variant: 'error',
            }))
            return
        }

        if (props.IDSRequest) {
            handlePricingResponse(true);
            return
        }
        setDialog(true);
        // validations
    }

    const handlePricingResponse = (response) => {
        if (response) {
            submitGenerateIDS();
        }
        setDialog(false);
    }

    const handleAskTOGenerateIDS = (response) => {
        setDialog(false);
        setDrawer(true)
    }


    // --- handle interactions * * * * * * * * * * * * * * * * * * * * * * * * * * *

    const submitGenerateIDS = async () => {
        setIDSProgress(true)
        // lets generate zip of uploaded files.
        const uploaded_zip_file = await generateZip(droppedFiles, "UPLOADED_FILE.zip");

        let formData = new FormData();
        if (props.IDSRequest) {
            formData.append('user', props.IDSRequest.user);
            formData.append('IDSRequest', props.IDSRequest.id);
        } else {
            formData.append('user', user.data.id);
        }
        formData.append('application_number', applicationNumber.replace('/', '').replace(',', ''))
        patentReferences && formData.append('patent_references', patentReferences);
        if (application.application) {
            formData.append('title', application.application.inventionTitle)
            formData.append('date', application.application.appFilingDate)
        }
        formData.append('application_data', JSON.stringify(application.application || {}))
        formData.append('patent_references_data', JSON.stringify(reference.patents || []))

        uploaded_zip_file && formData.append('files', uploaded_zip_file)


        for (let i = 0; i < NLPReferences.length; i++) {
            formData.append('nlp_references[]', NLPReferences[i])
        }
        for (let i = 0; i < NLPCheckboxes.length; i++) {
            formData.append('nlp_checkboxes[]', NLPCheckboxes[i])
        }
        axios.post(API_BASE_URL + 'ids/', formData, {
            headers: {'content-type': 'multipart/form-data'}
        })
            .then(res => {
                setIDS(res.data);
                dispatch(showMessage({
                    message: "IDS Generated successfully",
                    variant: 'success',
                }))
                setIDSProgress(false)
                executeScrollToTop();
                props.IDSRequest && props.onIDSGenerated(props.IDSRequest.id);
            })
            .catch(err => {
                setIDSProgress(false)
                dispatch(showMessage({
                    message: "IDS Generation Failed.",
                    variant: 'error',
                }))
            })
    }

    // --- API CALLS * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *


    const populateInitialsForAdminRequested = (IDSRequest) => {
        setApplicationNumber(IDSRequest.application_number);
        dispatch(getApplication(IDSRequest.application_number));
        if (IDSRequest.patent_references) {
            handlePatentReferences(IDSRequest.patent_references);
            dispatch(getPatents(IDSRequest.patent_references));
        }
        let temp_arr = []
        let temp_arr2 = []
        for (let i = 0; i < IDSRequest.nlp_references.length; i++) {
            const text = IDSRequest.nlp_references[i].text
            temp_arr = temp_arr.concat([text])
            temp_arr2 = temp_arr2.concat("NO")
        }
        setNLPReferences(temp_arr)
        setNLPCheckboxes(temp_arr2)

        setFilesDownloadingProgress(true)
        // FILES
        axios.get(API_BASE_URL + 'ids-requests/' + IDSRequest.id + '/files/' + `?timestamp=${new Date().getTime()}`, {
            responseType: 'blob'
        })
            .then(async res => {
                // to file
                setFilesDownloadingProgress(false)
                const files = await readZIP(res.data)
                setDroppedFiles(files)
            }).catch(err => {
            setFilesDownloadingProgress(false)
        })
    }

    useEffect(() => {
        // reset form
        dispatch(resetIDSGenerateForm());

        // in case of admin, populate fields initially.
        if (props.IDSRequest) {
            populateInitialsForAdminRequested(props.IDSRequest)
        }

    }, [])

    function getStepContent(step) {
        switch (step) {
            case 0:
                return (
                    <div className='pb-24'>
                        <div className='flex flex-row items-center'>
                            <TextField
                                label="Application Number"
                                placeholder="Specify Application Number & press Enter"
                                value={applicationNumber}
                                onChange={e => handleApplicationNumber(e.target.value)}
                                onKeyDown={e => e.keyCode === 13 && dispatch(getApplication(applicationNumber.replace('/', '').replace(',', '')))}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                autoFocus
                                variant="outlined"
                                className='mt-12 font-bold w-full max-w-md rounded-none bg-white'
                            />
                        </div>
                        <div className='flex'>
                            {
                                application.loading && (
                                    <div>
                                        <CircularProgress color={"secondary"} size={16} className='mt-12 mr-16'/>
                                    </div>
                                )
                            }

                            {
                                !application.application && application.label && (
                                    <Typography className='mt-12 opacity-75'>{application.label}</Typography>
                                )
                            }

                        </div>
                        {application.application && applicationNumber.replace('/', '').replace(',', '') === application.application.applId && (
                            <TableContainer component={Paper} className='rounded-none my-12 shadow max-w-md'>
                                <Table size={"small"} aria-label="a table">
                                    <TableHead>
                                        <TableRow className='font-bold'>
                                            <TableCell style={{width: 180}}>Application Number</TableCell>
                                            <TableCell colSpan={2}>{application.application.applId}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableRow className='font-bold'>
                                        <TableCell>Filing Date</TableCell>
                                        <TableCell align="left">{application.application.appFilingDate}</TableCell>
                                    </TableRow>
                                    <TableRow className='font-bold'>
                                        <TableCell>First Named Inventor</TableCell>
                                        <TableCell align="left">{application.application.inventorName}</TableCell>
                                    </TableRow>
                                    <TableRow className='font-bold'>
                                        <TableCell>Group Art Unit</TableCell>
                                        <TableCell align="left">{application.application.appGrpArtNumber}</TableCell>
                                    </TableRow>
                                    <TableRow className='font-bold'>
                                        <TableCell>Examiner Name</TableCell>
                                        <TableCell align="left">{application.application.appExamName}</TableCell>
                                    </TableRow>
                                    <TableRow className='font-bold'>
                                        <TableCell>Attorney Docket Number</TableCell>
                                        <TableCell align="left">{application.application.appAttrDockNumber}</TableCell>
                                    </TableRow>
                                    <TableRow className='font-bold'>
                                        <TableCell>Invention Title</TableCell>
                                        <TableCell align="left">{application.application.inventionTitle}</TableCell>
                                    </TableRow>
                                </Table>
                            </TableContainer>
                        )}


                    </div>
                )
            case 1:
                return (
                    <div className='pb-24'>
                        <div className='flex flex-row items-center'>
                            <TextField
                                label="Specify Comma Separated Patent Number(s) and Publication Number(s) and press Enter"
                                placeholder="Upto 30 patent references. If more, use 'Ask Us To Generate IDS' Option."
                                multiline
                                value={patentReferences}
                                onChange={e => handlePatentReferences(e.target.value)}
                                onKeyDown={e => e.keyCode === 13 && dispatch(getPatents(patentReferences))}
                                rowsMax={3}
                                rows={3}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                autoFocus
                                variant="outlined"
                                className='mt-12 font-bold w-full max-w-md bg-white'
                            />
                        </div>


                        <div className='flex'>
                            {
                                reference.loading && (
                                    <div>
                                        <CircularProgress color={"secondary"} size={16} className='mt-12 mr-16'/>
                                    </div>
                                )
                            }

                            {
                                reference.label && (
                                    <Typography className='mt-12 opacity-75'>{reference.label}</Typography>
                                )
                            }

                        </div>
                        <TableContainer
                            component={Paper} hidden={!reference.patents}
                            className='rounded-none my-12 shadow'>
                            <Table size={"small"} aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{width: 20}} className='p-1'>S.No.</TableCell>
                                        <TableCell style={{width: 50}} className='p-1'>Country</TableCell>
                                        <TableCell style={{width: 70}} className='p-1'>Document No.</TableCell>
                                        <TableCell align={"center"} className='p-1'>Kind</TableCell>
                                        <TableCell className='p-1'>Priority Date</TableCell>
                                        <TableCell className='p-1'>Publication Date</TableCell>
                                        <TableCell className='p-1'>Applicant Name</TableCell>
                                        <TableCell className='p-1'>Inventors</TableCell>
                                        <TableCell className='p-1'>Invention Title</TableCell>
                                    </TableRow>
                                </TableHead>
                                {
                                    reference.patents && reference.patents.map((row, index) => (
                                        <TableRow>
                                            <TableCell style={{width: 20}} className='p-1'
                                                       align={"center"}>{index + 1}</TableCell>
                                            <TableCell style={{width: 50}} className=''>{row.country}</TableCell>
                                            <TableCell style={{width: 70}} className='p-1'>{row.docNum}</TableCell>
                                            <TableCell align={"center"} className='p-0'>{row.kind}</TableCell>
                                            <TableCell style={{width: 100}}
                                                       className='p-1'>{row.priorityDate}</TableCell>
                                            <TableCell style={{width: 100}}
                                                       className='p-1'>{row.publicationDate}</TableCell>
                                            <TableCell className='p-1'>{row.applicantName}</TableCell>
                                            <TableCell className='p-1'>{row.firstInventor}</TableCell>
                                            <TableCell className='p-1'>{row.inventionTitle}</TableCell>
                                        </TableRow>
                                    ))
                                }

                            </Table>
                        </TableContainer>


                    </div>
                );
            case 2:
                return (
                    <div className='pb-24'>
                        {
                            NLPReferences.map((text, index) => (
                                <div className='flex flex-row items-center'>
                                    <Tooltip
                                        className='text-14'
                                        title="Tick if English Translation is attached for this NPL Reference in 4 below"
                                        aria-label="add" placement="top">
                                        <Checkbox
                                            key={index}
                                            className={"npl_checkbox"}
                                            checked={NLPCheckboxes[index] === "YES"}
                                            onChange={(e) => handleCheckNLPReferenceChange(index, e.target.checked)}
                                        />
                                    </Tooltip>
                                    <TextField
                                        placeholder="Add NPL Reference"
                                        multiline
                                        rowsMax={3}
                                        rows={2}
                                        value={text}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={e => handleEditNLPReference(index, e.target.value)}
                                        variant="outlined"
                                        className='mt-12 font-bold w-full max-w-md bg-white'
                                    />
                                    {
                                        text !== ''
                                        &&
                                        <Icon
                                            onClick={() => handleRemoveNLPReference(index)}
                                            className='text-red-light'>delete</Icon>
                                    }

                                </div>
                            ))

                        }
                        <div className='py-8'>
                            <Button
                                disabled={NLPReferences[NLPReferences.length - 1] === ''}
                                variant='outlined' className='rounded-none font-bold'
                                onClick={handleAddNLPReference}
                            >+ Add more</Button>
                        </div>

                    </div>
                )

            case 3:
                return (
                    <div className='pb-24'>
                        <Dropzone
                            onDropRejected={() => window.alert("Something went wrong")}
                            onDrop={handleFilesDrop}
                            accept={ALLOWED_FILETYPES_GENERATE_IDS}
                        >
                            {({getRootProps, getInputProps}) => (
                                <section
                                    className='border border-dashed rounded-lg h-128 border-gray-700 bg-gray-100 max-w-md'>
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <p className='p-8 text-center align-middle py-48'>Drag 'n' drop file here, or
                                            click to
                                            select files</p>
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                        {filesDownloadingProgress && (
                            <Typography className='my-12 text-16'><CircularProgress color={"secondary"}
                                                                                    size={16}/> Please wait, files are
                                downloading...</Typography>
                        )}
                        <TableContainer component={Paper} hidden={droppedFiles.length <= 0}
                                        className='rounded-none my-12 shadow max-w-md'>
                            <Table size={"small"} aria-label="a table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>S.No.</TableCell>
                                        <TableCell>Filename</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                {
                                    droppedFiles.map((file, index) => (
                                        <TableRow>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>{file.name}</TableCell>
                                            <TableCell><Icon onClick={() => handleFileRemove(index)}
                                                             className='text-red-light'>delete</Icon></TableCell>
                                        </TableRow>
                                    ))
                                }

                            </Table>
                        </TableContainer>

                    </div>
                );
            case 4:
                return (
                    <div className='pb-24'>
                        <TableContainer component={Paper} className='rounded-none my-12 shadow max-w-lg'>
                            <Table aria-label="a table">
                                <TableRow>
                                    <TableCell style={{width: 200}} className='font-bold'>Application Number</TableCell>
                                    <TableCell colSpan={2}>{applicationNumber ? applicationNumber : <span
                                        className='text-red-light italic'>Application number is required*</span>}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className='font-bold'>Patent References</TableCell>
                                    <TableCell align="left">
                                        {reference.patents && reference.label === null ? (
                                                <Typography className='max-w-md'
                                                            style={{wordWrap: "break-word"}}>{patentReferences}</Typography>
                                            ) :
                                            (
                                                patentReferences && reference.label ?
                                                    <Typography className='text-red'>{reference.label}</Typography>
                                                    :
                                                    <Typography className='opacity-50'>No Patent References</Typography>

                                            )}

                                    </TableCell>

                                </TableRow>
                                <TableRow>
                                    <TableCell className='font-bold'>Title</TableCell>
                                    <TableCell
                                        align="left">{application.application && application.application.inventionTitle}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className='font-bold'>Date</TableCell>
                                    <TableCell
                                        align="left">{application.application && application.application.appFilingDate}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className='font-bold'>Files</TableCell>
                                    <TableCell align="left">
                                        {droppedFiles.map(file => file.name + ", ")}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className='font-bold'>NPL References</TableCell>
                                    <TableCell align="left">
                                        {NLPReferences.map(text => (<Typography>{text}</Typography>))}
                                    </TableCell>
                                </TableRow>
                            </Table>
                        </TableContainer>
                    </div>
                )
            default:
                return 'Unknown step';
        }
    }

    // STEPPER * * * * * * * * * * * * * * * * * * * * * * ** * * * * * * * * * * *

    return (
        <FusePageSimple

            content={
                <div ref={topRef}>
                    {!props.IDSRequest && !IDS && (
                        <div>
                            <div className='flex flex-row items-center px-24 py-16'>
                                <Icon color={'secondary'}>assignment</Icon>
                                <Typography color={"secondary"} className='ml-8 text-xl'>Generate New IDS</Typography>
                            </div>
                            <Divider/>
                        </div>
                    )}

                    {/* eslint-disable-next-line react/jsx-no-undef */}
                    {!IDS && (
                        <Stepper className='bg-transparent' activeStep={activeStep} orientation="vertical">
                            {getSteps().map((label, index) => (
                                <Step key={label} className='cursor-pointer'>
                                    <StepLabel
                                        className='font-bold'
                                        onClick={() => goToStep(index)}>
                                        <Typography className='text-lg'>{label}</Typography>
                                    </StepLabel>
                                    <StepContent>
                                        {getStepContent(index)}
                                        <div>
                                            <div>
                                                <Button
                                                    disabled={activeStep === 0}
                                                    onClick={() => setActiveStep(activeStep - 1)}
                                                >
                                                    Back
                                                </Button>

                                                {activeStep === getSteps().length - 1 ? (
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            onClick={handleConfirmGenerateIDS}
                                                            disabled={!applicationNumber || IDSProgress ||
                                                            (patentReferences && (reference.label !== null || reference.patents === null || (reference.patentReferences !== patentReferences)))
                                                            }
                                                        >
                                                            {!IDSProgress ? 'Confirm and Generate IDS' :
                                                                <>Generating IDS <CircularProgress className='ml-12'
                                                                                                   size={20}/></>
                                                            }

                                                        </Button>
                                                    )
                                                    :
                                                    (
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            onClick={goNext}
                                                            disabled={activeStep === getSteps().length - 1}
                                                        >
                                                            {activeStep === getSteps().length - 1 ? 'END' : 'Next'}
                                                        </Button>
                                                    )
                                                }

                                            </div>
                                        </div>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                    )}


                    {IDS &&
                    <div className='m-8 p-8 items-center text-center'>
                        <Typography className='font-light text-center text-2xl text-green-light mb-24 mt-12'>IDS has
                            been
                            generated successfully 🎊</Typography>
                        <IDSDetail IDS={IDS}/>
                        {!props.IDSRequest &&
                        <Button
                            variant={"outlined"}
                            component={Link}
                            to='generate-ids-new'
                            className='block mx-auto rounded-none my-24 cursor-pointer'
                            color={"secondary"}>Generate new IDS</Button>
                        }
                    </div>
                    }

                    <Dialog maxWidth={"md"}
                            open={dialog}
                            onClose={() => setDialog(false)}
                    >
                        <div className='p-24' hidden={patentReferences.split(',').length > 30 && !props.IDSRequest}>
                            <Typography className='text-lg font-light'>This action costs an amount. You need to pay for
                                this.</Typography>
                            <Typography
                                className='text-xl my-12 font-light text-green-light'>{DEFAULT_IDS_AMOUNT}$</Typography>
                            <div className='flex justify-end'>
                                <Button className='rounded-none' variant={"text"} color={"secondary"}
                                        onClick={() => handlePricingResponse(false)}
                                >Cancel</Button>
                                <Button className='rounded-none ml-12' variant={"contained"} color={"secondary"}
                                        onClick={() => handlePricingResponse(true)}
                                >Agree & Continue</Button>
                            </div>

                        </div>
                        <div className='p-24' hidden={patentReferences.split(',').length <= 30 || props.IDSRequest}>
                            <Typography className='text-lg'>There are more than 30 References. Please use 'Ask Us To Generate IDS' Option</Typography>
                            <div className='flex justify-end my-5'>
                                <Button className='rounded-none' variant={"text"} color={"secondary"}
                                        onClick={() => handlePricingResponse(false)}
                                >Cancel</Button>
                                <Button className='rounded-none ml-12' variant={"contained"} color={"secondary"}
                                        onClick={() => handleAskTOGenerateIDS(true)}
                                >Ask Us To Generate IDS</Button>
                            </div>

                        </div>
                    </Dialog>


                    <Drawer anchor='right' open={drawer}>
                        <div className='w-2xl'>
                            <AppBar position="static" elevation={0}>
                                <Toolbar className="flex w-full">
                                    <IconButton
                                        component={Link}
                                        to='generate-ids-new' onClick={() => setDrawer(false)}><Icon
                                        className='text-white opacity-75'>close</Icon></IconButton>
                                </Toolbar>
                            </AppBar>
                            <AskGenerateIDS
                                applicationNumber={applicationNumber}
                                patentReferences={patentReferences}
                                NLPReferences={NLPReferences}
                                droppedFiles={droppedFiles}
                            />
                        </div>
                    </Drawer>

                </div>
            }

        />
    )
}

export default GenerateIDS;
