import React, {useState} from "react";
import Header from "./components/Header";
import {
    Button,
    Divider,
    Icon,
    LinearProgress,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    TextField,
    Typography
} from "@material-ui/core";
import {API_BASE_URL, CAPTCHA_SITE_KEY, FEEDBACK_CATEGORIES} from "../../fuse-configs/constants";
import ReCAPTCHA from "react-google-recaptcha";
import Formsy from "formsy-react";
import {isEmail} from "validator";
import {useDispatch} from "react-redux";
import {showMessage} from "../../store/actions";

import axios from 'axios';

const htmlFile = require('./static_html_files/feedback.html')
let template = {__html: htmlFile};

const Feedback = (props) => {
    const dispatch = useDispatch();
    const [category, setCategory] = useState(FEEDBACK_CATEGORIES[0]);
    const [companyName, setCompanyName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [captcha, setCaptcha] = useState(null);

    const [progress, setProgress] = useState(false);
    const [success, setSuccess] = useState(false);

    const showError = (message) => {
        dispatch(showMessage({
            message: message,
            variant: 'error'
        }))
    }

    const submitForm = () => {

        // todo STRIP EVERYTHING.

        if (companyName === "") {
            showError("Company name can't be blank");
            return
        }
        if (firstName === "") {
            showError("First Name can't be blank");
            return
        }
        if (lastName === "") {
            showError("Last Name can't be blank");
            return
        }
        if (email === "") {
            showError("Email can't be blank");
            return
        }
        if (subject === "") {
            showError("Subject can't be blank");
            return
        }
        if (message === "") {
            showError("Message can't be blank");
            return
        }
        if (!isEmail(email)) {
            showError("Please enter a valid Email");
            return
        }

        // todo CALL API HERE.

        setProgress(true)

        axios.post(API_BASE_URL + 'feedback/', {
            category:category,
            company_name:companyName,
            first_name:firstName,
            last_name:lastName,
            subject:subject,
            message:message,
            email:email
        })
            .then(res=>{
                console.log(res.data)
                dispatch(showMessage({
                    message: "Feedback sent successfully",
                    variant:'success'
                }))
                setProgress(false)
                setSuccess(true)
            }).catch(err=>{
            console.log(err)
            dispatch(showMessage({
                message: "Feedback sending failed",
                variant:'error'
            }))

            setProgress(false)
        })

    }

    return (
        <div className=''>
            <Header {...props}/>
            <div className="pb-64 items-center">
                {/*<div dangerouslySetInnerHTML={template} />*/}
                {success && <Paper className='max-w-xl block mx-auto my-24 p-24 shadow rounded-none flex flex-col'>

                    <Typography className='text-lg p-24 text-green-light font-light'>Feedback has been submitted.</Typography>
                </Paper>}
                {!success &&
                <Paper className='max-w-xl block mx-auto my-24 p-24 shadow rounded-none flex flex-col'>
                    <div
                        className='flex flex-row items-center content-center mb-12 -ml-80 -mt-24 bg-white rounded p-12'>
                        <Icon fontSize={"large"} color={"secondary"}>message</Icon>
                        <Typography className='text-2xl ml-24 mb-1'>Send HatchIDS a Message</Typography>
                    </div>
                    <Select
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                        variant={"outlined"}
                        className='text-16 max-w-md mb-12'
                    >
                        {FEEDBACK_CATEGORIES.map(c => (
                            <MenuItem value={c}>{c}</MenuItem>
                        ))}
                    </Select>
                    <TextField
                        className='max-w-md mb-12 text-16'
                        placeholder='Company Name'
                        label='Company Name'
                        InputLabelProps={{}}
                        variant="outlined"

                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                    />
                    <div className='flex flex-row max-w-md'>
                        <TextField
                            className='max-w-md mb-12 text-16 flex-1 mr-4'
                            placeholder='First Name'
                            label='First Name'
                            InputLabelProps={{}}
                            variant="outlined"

                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                        <TextField
                            className='max-w-md mb-12 text-16 flex-1 ml-4'
                            placeholder='Last Name'
                            label='Last Name'
                            InputLabelProps={{}}
                            variant="outlined"

                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                    </div>
                    <TextField
                        className='max-w-md mb-12 text-16'
                        placeholder='Email'
                        label='Email'
                        InputLabelProps={{}}
                        variant="outlined"

                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        className='max-w-md mb-12 text-16'
                        placeholder='Subject'
                        label='Subject'
                        InputLabelProps={{}}
                        variant="outlined"

                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                    />
                    <TextField
                        className='max-w-md mb-12 text-16'
                        placeholder='Message'
                        label='Message'
                        multiline
                        rows={6}
                        InputLabelProps={{}}
                        variant="outlined"

                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />

                    <ReCAPTCHA
                        sitekey={CAPTCHA_SITE_KEY}
                        onChange={(value) => setCaptcha(value)}
                    />

                    <Button
                        className='shadow-0 my-12 max-w-md' color={"secondary"}
                        variant={"contained"}
                        onClick={submitForm}
                        disabled={captcha === null}
                    >
                        Submit
                    </Button>
                    <LinearProgress color={"secondary"} hidden={!progress}/>
                </Paper>
                }
            </div>
        </div>
    );

}

export default Feedback;
