import {authRoles} from "../auth";

const navigationConfig = [
    {
        'id'      : 'Menu',
        'title'   : 'menu',
        'type'    : 'group',
        'icon'    : 'apps',
        'children': [
            {
                'id'   : 'home',
                'title': 'Home',
                'type' : 'item',
                'icon' : 'home',
                'url'  : '/home',
                'auth' : authRoles.user
            },
            {
                'id'   : 'users',
                'title': 'Users',
                'type' : 'item',
                'icon' : 'person',
                'url'  : '/users',
                'auth' : authRoles.admin
            },
            {
                'id'   : 'generate_ids',
                'title': 'Generate IDS',
                'type' : 'item',
                'icon' : 'assignment',
                'url'  : '/generate-ids',
                'auth' : authRoles.onlyUser
            },
            {
                'id'   : 'ids_list_user',
                'title': 'My IDS',
                'type' : 'item',
                'icon' : 'dns',
                'url'  : '/ids-list',
                'auth' : authRoles.onlyUser
            },
            {
                'id'   : 'ids_list_admin',
                'title': 'User Generated IDS',
                'type' : 'item',
                'icon' : 'dns',
                'url'  : '/ids-list',
                'auth' : authRoles.admin
            },
            {
                'id'   : 'ask_admin_generate_ids',
                'title': 'Ask Us To Generate IDS',
                'type' : 'item',
                'icon' : 'crop_landscape',
                'url'  : '/ask-generate-ids',
                'auth' : authRoles.onlyUser
            },
            {
                'id'   : 'ids_requests',
                'title': 'IDS Generation Requests',
                'type' : 'item',
                'icon' : 'assignment',
                'url'  : '/ids-requests',
                'auth' : authRoles.admin
            },
            {
                'id'   : 'invoices',
                'title': 'Invoices',
                'type' : 'item',
                'icon' : 'receipt',
                'url'  : '/invoice-list',
                'auth' : authRoles.user
            },
            {
                id: 'blog',
                title: 'Insight',
                type: 'collapse',
                icon: 'list_alt',
                url: '/blog',
                'auth' : authRoles.admin,
                children : [
                    {
                        'id'       : 'blog-list',
                        'title'    : 'All Blogs',
                        'type'     : 'item',
                        'icon'     : 'list',
                        'url'      : '/insight',
                        'exact':true,
                    },
                    {
                        'id'       : 'blog-create',
                        'title'    : 'Add Post',
                        'type'     : 'item',
                        'icon'     : 'add',
                        'url'      : '/blog/create'
                    }
                ]
            },
            {
                'id'       : 'blog-list',
                'title'    : 'Blogs',
                'type'     : 'item',
                'icon'     : 'list',
                'url'      : '/blog',
                'exact':true,
                'auth' : authRoles.onlyUser
            },

            {
                'id'   : 'settings',
                'title': 'Settings',
                'type' : 'item',
                'icon' : 'settings',
                'url'  : '/settings',
                'auth' : authRoles.user
            },
        ]
    },
    /*{
        'id'      : 'links',
        'title'   : 'Extra Links',
        'type'    : 'group',
        'icon'    : 'label_important',
        'children': [
            {
                'id'   : 'terms-and-conditions',
                'title': 'Terms & Conditions',
                'type' : 'item',
                'icon' : 'link',
                'url'  : '/terms-and-conditions-app',
            },

        ]
    }*/
];

export default navigationConfig;
