import React, {useEffect, useState} from "react";
import {
    Button, CircularProgress, Icon,
    LinearProgress,
    Paper,
    Table,
    TableCell,
    TableContainer, TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import axios from "axios";
import {ALLOWED_FILETYPES_GENERATE_IDS, API_BASE_URL} from "../../fuse-configs/constants";
import {saveAs} from 'file-saver';
import {useDispatch, useSelector} from "react-redux";
import {hideMessage, showMessage} from "../../store/actions";
import Dropzone from "react-dropzone";
import {generateZip} from "../../utils";

export default function IDSDetail(props) {
    const dispatch = useDispatch();
    const user = useSelector(({auth}) => auth.user);
    const [IDS, setIDS] = useState(props.IDS)
    const [progress, setProgress] = useState(false);
    const [files_progress, set_files_progress] = useState(false);
    const [generatingPDFs, setGeneratingPDFs] = useState(false);
    const [generatingPDFMessage, setGeneratingPDFMessage] = useState('Generate Patent References (PDFs)');

    const [droppedFiles, setDroppedFiles] = useState([])
    const [filesUploadingProgress, setFilesUploadingProgress] = useState(false)

    const [uploadMessage, setUploadMessage] = useState('')


    const get_files = () => {
        set_files_progress(true)
        axios.get(API_BASE_URL + 'ids/' + IDS.id + '/files/' + `?timestamp=${new Date().getTime()}`, {
            responseType: 'blob'
        })
            .then(res => {
                saveAs(res.data, `${IDS.application_number}_UPLOADED_FILES.zip`);
                set_files_progress(false)
            }).catch(err => {
            set_files_progress(false)
        })
    }

    const get_sb08_file = () => {
        setProgress(true)
        axios.get(API_BASE_URL + 'ids/' + IDS.id + '/sb08-file/' + `?timestamp=${new Date().getTime()}`, {
            responseType: 'blob'
        })
            .then(res => {
                saveAs(res.data, `SB08_${IDS.application_number}.pdf`);
                setProgress(false)
            }).catch(err => {
            setProgress(false)
        })
    }
    const get_patent_references_file = () => {
        setProgress(true)
        axios.get(API_BASE_URL + 'ids/' + IDS.id + '/patent-references-files/' + `?timestamp=${new Date().getTime()}`, {
            responseType: 'blob'
        })
            .then(res => {
                saveAs(res.data, `PatentReferencesPDFs_${IDS.application_number}.zip`);
                setProgress(false)
                props.doRefresh && props.doRefresh()
            }).catch(async err => {
            const message = JSON.parse(await err.response.data.text()).message || "Your references are being generated. Download Option would be activated soon."
            setGeneratingPDFs(true)
            setUploadMessage(message)
            setProgress(false)
            props.doRefresh && props.doRefresh()
        })
    }

    const fetchIDS = (id) => {
        axios.get(API_BASE_URL + 'ids/' + id + '/' + `?timestamp=${new Date().getTime()}`)
            .then(res => {
                setIDS(res.data)
                if(res.data.is_patent_reference_files_generating){
                    if(uploadMessage === ''){
                        setUploadMessage('Your references are being generated. Download Option would be activated soon.')
                    }
                } else {
                    setUploadMessage('')
                }
            }).catch(err => {
            console.log(err)
        })
    }

    const handleFilesDrop = (files) => {
        setDroppedFiles(droppedFiles.concat(files))

    }
    const handleFileRemove = (target_index) => {
        setDroppedFiles(droppedFiles.filter((file, index) => index !== target_index));
    }

    useEffect(() => {
        if(IDS.is_patent_reference_files_generating){
            if(uploadMessage === ''){
                setUploadMessage('Files have started generating on the server. Please check after some time...')
            } else {
                setUploadMessage('')
            }
        }
        const timeInterval = setInterval(
            () => fetchIDS(props.IDS.id),
            3000)
        return () => clearInterval(timeInterval)


    }, [])


    const submitGenerateIDSRequest = async () => {
        setFilesUploadingProgress(true)

        // lets generate zip of uploaded files.
        const uploaded_zip_file = await generateZip(droppedFiles, "UPLOADED_FILE.zip");

        let formData = new FormData();

        uploaded_zip_file && formData.append('file', uploaded_zip_file)

        axios.post(API_BASE_URL + 'ids/' + IDS.id + '/upload_patent_references/', formData, {
            headers: {'content-type': 'multipart/form-data'}
        })
            .then(res => {
                dispatch(showMessage({
                    message: "Files uploaded successfully",
                    variant: 'success',
                }))
                setFilesUploadingProgress(false)
                setDroppedFiles([])
            })
            .catch(err => {
                setFilesUploadingProgress(false)
                dispatch(showMessage({
                    message: "Files uploading Failed.",
                    variant: 'error',
                }))
            })
    }


    return (
        <div>
            <TableContainer component={Paper} className='rounded-none shadow max-w-lg mx-auto block'>
                {uploadMessage !== '' &&
                <Paper className='rounded-12 bg-gray-700 mb-24 max-w-lg shadow-0'>
                    <Typography className='p-4 text-center text-white'>{uploadMessage}</Typography>
                </Paper>}
                <Table aria-label="a table">
                    <TableRow>
                        <TableCell className='font-bold'>Application Number</TableCell>
                        <TableCell colSpan={2}>{IDS.application_number}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className='font-bold'>Title</TableCell>
                        <TableCell align="left">{IDS.title}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell className='font-bold'>Patent References</TableCell>
                        <TableCell align="left">
                            <Typography className='max-w-md'
                                        style={{wordWrap: "break-word"}}>{IDS.patent_references}</Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className='font-bold'>Uploaded NPL/Related References</TableCell>
                        <TableCell align="left">
                            {IDS.files ?
                                <a className='m-4 text-lg font-light cursor-pointer items-center text-blue'
                                   onClick={get_files}
                                >
                                    Click here to download uploaded files
                                </a>
                                :
                                <Typography className='opacity-50'>
                                    No Files
                                </Typography>
                            }


                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className='font-bold'>NPL References</TableCell>
                        <TableCell>
                            {IDS.nlp_references && IDS.nlp_references.map(row => (
                                <Typography>{row.text}</Typography>
                            ))}
                        </TableCell>

                    </TableRow>
                    {/*<TableRow>*/}
                    {/*<TableCell>*/}
                    {/*</TableCell>*/}
                    {/*<TableCell>*/}

                    {/*    <Button variant={"contained"}*/}
                    {/*            disabled*/}
                    {/*            className='rounded-none bg-green-light hover:border-black ml-12 hover:bg-green-dark block'*/}
                    {/*            color={"primary"}>Download Patent references Files</Button>*/}

                    {/*</TableCell>*/}
                    {/*</TableRow>*/}

                </Table>
                <div className='flex flex-row items-start'>
                    <Button
                        variant={"contained"}
                        className='rounded-none bg-green-light hover:border-black hover:bg-green-dark m-8'
                        color={"primary"}
                        onClick={get_sb08_file}
                    >
                        Download SB08 (IDS) Form
                    </Button>
                    <div className='max-w-sm'>
                        <Button
                            variant={"contained"}
                            className='rounded-none bg-blue hover:border-black hover:bg-blue-dark m-8 block'
                            color={"primary"}
                            disabled={(generatingPDFs || progress || IDS.is_patent_reference_files_generating || IDS.extra_data.patent_references.status === "success" || IDS.extra_data.patent_references.status === "failed" || IDS.extra_data.patent_references.data?.length === 0)}
                            onClick={get_patent_references_file}
                        >
                            {IDS.is_patent_reference_files_generating &&
                            <CircularProgress size={20} className='mr-8 -mb-4'/>}
                            {IDS.extra_data.patent_references.data?.length === 0 ? 'No Patent references to generate' : IDS.is_patent_reference_files_generating ? "PDF Generation is In Progress" : generatingPDFMessage}
                        </Button>
                        <Button
                            variant={"contained"}
                            className='rounded-none bg-blue hover:border-black hover:bg-blue-dark m-8 block'
                            color={"primary"}
                            disabled={IDS.extra_data.patent_references.status !== "success" || IDS.is_patent_reference_files_generating}
                            onClick={get_patent_references_file}
                        >
                            {progress && IDS.extra_data.patent_references.status === "success" &&
                            <CircularProgress color='#fff' size={20} className='mr-8 -mb-4'/>}
                            Download Patent References (PDFs)
                        </Button>

                        {
                            IDS.extra_data.patent_references.status === "success" ?
                                <Typography style={{wordWrap: "break-word"}} className='m-8 p-8 border-gray-300'
                                            color={"textSecondary"}>
                                    {IDS.extra_data.patent_references.message}
                                </Typography>
                                :
                                <Typography style={{wordWrap: "break-word"}}
                                            className='m-8 p-8 border-gray-300 text-red'>
                                    {IDS.extra_data.patent_references.message}
                                </Typography>

                        }

                    </div>

                </div>
                {files_progress && <LinearProgress color={"secondary"}/>}
            </TableContainer>

            {user.role === 'admin' && (
                <TableContainer component={Paper} className='rounded-none shadow max-w-lg mx-auto block'>
                    <Table aria-label="a table">
                        <TableRow>
                            <TableCell className='font-bold'>Admin Actions</TableCell>
                            <TableCell colSpan={2}/>
                        </TableRow>
                        <TableRow>
                            <TableCell>Upload Patent references (PDFs)</TableCell>
                            <TableCell align="left">
                                <Dropzone

                                    onDropRejected={() => window.alert("Something went wrong")}
                                    onDrop={handleFilesDrop}
                                >
                                    {({getRootProps, getInputProps}) => (
                                        <section
                                            className='border border-dashed rounded-lg h-64 mb-12 border-gray-700 bg-gray-100 max-w-md mt-12'>
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <p className='p-8 text-center align-middle py-24'>Drag 'n' drop file
                                                    here,
                                                    or
                                                    click to
                                                    select files</p>
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                                <TableContainer component={Paper} hidden={droppedFiles.length <= 0}
                                                className='rounded-none mb-12 shadow max-w-md'>
                                    <Table size={"small"} aria-label="a table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>S.No.</TableCell>
                                                <TableCell>Filename</TableCell>
                                                <TableCell>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {
                                            droppedFiles && droppedFiles.map((file, index) => (
                                                <TableRow>
                                                    <TableCell>{index + 1}</TableCell>
                                                    <TableCell>{file.name}</TableCell>
                                                    <TableCell><Icon onClick={() => handleFileRemove(index)}
                                                                     className='text-red-light cursor-pointer'>delete</Icon></TableCell>
                                                </TableRow>
                                            ))
                                        }

                                    </Table>
                                </TableContainer>
                                <Button disabled={droppedFiles.length === 0}
                                        color='secondary'
                                        onClick={submitGenerateIDSRequest}
                                        className='rounded-none'
                                        variant={"contained"}>
                                    {filesUploadingProgress &&
                                    <CircularProgress color='#fff' size={20} className='mr-8 -mb-4'/>}
                                    Upload</Button>
                            </TableCell>
                        </TableRow>
                    </Table>
                </TableContainer>
            )}
        </div>
    )
}
