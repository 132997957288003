import axios from 'axios';
import jwtDecode from 'jwt-decode';
import FuseUtils from '@fuse/FuseUtils';
import {API_BASE_URL} from "../../fuse-configs/constants";

class jwtService extends FuseUtils.EventEmitter {

    init() {
        this.setInterceptors();
        this.handleAuthentication();
    }

    setInterceptors = () => {
        axios.interceptors.response.use(response => {
            return response;
        }, err => {
            return new Promise((resolve, reject) => {
                if (err.response && err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
                    // if you ever get an unauthorized response, logout the user
                    this.emit('onAutoLogout', 'Invalid Credentials');
                    this.setSession(null);
                }
                throw err;
            });
        });
    };

    handleAuthentication = () => {

        let access_token = this.getAccessToken();

        if (!access_token) {
            return;
        }

        if (this.isAuthTokenValid(access_token)) {
            this.setSession(access_token);
            this.emit('onAutoLogin', true);
        } else {
            this.setSession(null);
            this.emit('onAutoLogout', 'Login Expired');
        }
    };

    createUser = (data) => {
        return new Promise((resolve, reject) => {
            axios.post(API_BASE_URL + 'auth/signup/', data)
                .then(response => {
                    if (response.data) {
                        this.setSession(response.data.token);
                        resolve(response.data);
                    } else {
                        reject("Something went wrong")
                    }
                }).catch(err => {
                try {
                    reject(err.response.data.msg)
                } catch (e) {
                    reject("Something went wrong")
                }

            });
        });
    };

    signInWithEmailAndPassword = (email, password) => {
        return new Promise((resolve, reject) => {
            axios.post(API_BASE_URL + 'auth/login/', {
                email: email,
                password: password
            }).then(response => {
                if (response.data) {
                    this.setSession(response.data.token);
                    resolve(response.data);
                } else {
                    reject("Something went wrong.")
                }
            }).catch(err => {
                try {
                    reject(err.response.data.msg)
                } catch (e) {
                    reject("Something went wrong")
                }

            });
        });
    };

    signInWithToken = () => {
        return new Promise((resolve, reject) => {
            axios.post(API_BASE_URL + 'auth/login/', {
                data: {
                    access_token: this.getAccessToken()
                }
            })
                .then(response => {
                    if (response.data) {
                        this.setSession(response.data.token);
                        resolve(response.data);
                    } else {
                        reject(response.data.msg);
                    }
                });
        });
    };

    updateUserData = (user) => {
        return axios.post('/api/auth/user/update', {
            user: user
        });
    };

    setSession = access_token => {
        if (access_token) {
            localStorage.setItem('jwt_access_token', access_token);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
        } else {
            localStorage.removeItem('jwt_access_token');
            delete axios.defaults.headers.common['Authorization'];
        }
    };

    logout = () => {
        this.setSession(null);
    };

    isAuthTokenValid = access_token => {
        if (!access_token) {
            return false;
        }
        const decoded = jwtDecode(access_token);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
            // console.warn('access token expired');
            return false;
        } else {
            return true;
        }
    };

    getAccessToken = () => {
        return window.localStorage.getItem('jwt_access_token');
    };
}

const instance = new jwtService();

export default instance;
