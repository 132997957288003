import React from 'react';
import {AppBar, Toolbar,Typography} from '@material-ui/core';
import {ThemeProvider} from '@material-ui/styles';
import {useSelector} from 'react-redux';

function FooterLayout1(props)
{
    const footerTheme = useSelector(({fuse}) => fuse.settings.footerTheme);

    return (
        <ThemeProvider theme={footerTheme}>
            <AppBar id="fuse-footer" className="relative z-10" color="default">
                <div className='block justify-center my-16 flex flex-col flex-wrap mx-auto'>
                    <div className='py-8'>
                        <Typography className=' text-center' color={"secondary"}>
                            <a href='/' className='pr-4 border-r-1' style={{borderColor:'#404040'}}>Home</a>
                            <a href='/blog' className='ml-4 pr-4 border-r-1' style={{borderColor:'#404040'}}>Blogs</a>
                            <a href='/terms-and-conditions' className='ml-4 pr-4 border-r-1' style={{borderColor:'#404040'}}>Terms & Conditions</a>
                            <a href='/faqs' className='ml-4 pr-4 border-r-1' style={{borderColor:'#404040'}}>FAQs</a>
                            <a href='/about-us' className='ml-4 pr-4 border-r-1' style={{borderColor:'#404040'}}>About Us</a>
                            <a href='/contact-us' className='ml-4 pr-4 border-r-1' style={{borderColor:'#404040'}}>Contact Us</a>
                            <a href='/feedback' className='ml-4 pr-4 border-r-1' style={{borderColor:'#404040'}}>Feedback</a>
                            <a href='/privacy-policy' className='ml-4' style={{borderColor:'#404040'}}>Privacy Policy</a>
                        </Typography>
                    </div>
                    <div className=' py-8'>
                        <Typography className='text-gray-500 font-light text-center'>Copyright © 2024 IIPRD. All rights reserved.</Typography>
                    </div>
                </div>
            </AppBar>
        </ThemeProvider>
    );
}

export default FooterLayout1;
