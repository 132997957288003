import React from "react";
import {
    AppBar,
    Button,
    Dialog,
    Icon,
    LinearProgress,
    Paper,
    Table, TableCell, TableContainer,
    TableRow,
    Toolbar,
    Typography
} from "@material-ui/core";
import {API_BASE_URL} from "../../fuse-configs/constants";
import axios from "axios";
import {useState} from "react";
import {useDispatch} from 'react-redux';
import {showMessage} from "../../store/actions";


export default function UserDialog(props) {
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    function doApproveUser(user_id) {

        const url = API_BASE_URL + 'auth/approve/';
        setLoading(true);

        axios.post(url, {
            user: user_id
        }).then(res => {
            setLoading(false)
            props.onApproveUser(user_id);
            dispatch(showMessage({
                message: "User approved!",
                variant: 'success'
            }))
        }).catch(err => {
            setLoading(false);
            dispatch(showMessage({
                message: (err.response && err.response.data && err.response.data.msg) || "User approving failed",
                variant: 'error'
            }))
        })

    }

    function doDenyUser(user_id) {

        const url = API_BASE_URL + 'auth/deny/';
        setLoading(true);

        axios.post(url, {
            user: user_id
        }).then(res => {
            setLoading(false)
            props.onDenyUser(user_id);
            dispatch(showMessage({
                message: "User Denied!",
                variant: 'success'
            }))
        }).catch(err => {
            setLoading(false);
            dispatch(showMessage({
                message: (err.response && err.response.data && err.response.data.msg) || "User denying failed",
                variant: 'error'
            }))
        })

    }


    function doBlockUser(user_id) {

        const url = API_BASE_URL + 'auth/block/';
        setLoading(true);

        axios.post(url, {
            user: user_id
        }).then(res => {
            setLoading(false)
            dispatch(showMessage({
                message: "User Blocked!",
                variant: 'success'
            }))
            props.onBlockUser(user_id)
        }).catch(err => {
            setLoading(false);
            dispatch(showMessage({
                message: (err.response && err.response.data && err.response.data.msg) || "User blocking failed",
                variant: 'error'
            }))
        })

    }

    function doUnblockUser(user_id) {

        const url = API_BASE_URL + 'auth/unblock/';
        setLoading(true);

        axios.post(url, {
            user: user_id
        }).then(res => {
            setLoading(false)
            dispatch(showMessage({
                message: "User Unblocked!",
                variant: 'success'
            }))
            props.onUnblockUser(user_id)
        }).catch(err => {
            setLoading(false);
            dispatch(showMessage({
                message: (err.response && err.response.data && err.response.data.msg) || "User unblocking failed",
                variant: 'error'
            }))
        })

    }


    function doDeleteUser(user_id) {

        const url = API_BASE_URL + 'auth/users/' + user_id + '/';
        setLoading(true);

        axios.delete(url, {
            user: user_id
        }).then(res => {
            setLoading(false)
            props.onDenyUser(user_id);  // SAME for DELETE so that using same
            dispatch(showMessage({
                message: "User Deleted!",
                variant: 'success'
            }))
        }).catch(err => {
            setLoading(false);
            dispatch(showMessage({
                message: (err.response && err.response.data && err.response.data.msg) || "User deleting failed",
                variant: 'error'
            }))
        })

    }


    const {
        selectedUser,
        isOpenUserDialog,
        toggleUserDialog,
        type
    } = props;
    // type = DETAILS(default) | APPROVE | DENY
    return (
        <Dialog maxWidth={"xs"}
                open={isOpenUserDialog}
                onClose={toggleUserDialog}>
            <AppBar position="static" elevation={1}>
                <Toolbar className="flex w-full text-blue bg-gray-100">
                    <Typography className='min-w-xs text-lg' color="inherit">
                        {selectedUser.email}
                    </Typography>
                </Toolbar>
            </AppBar>
            <TableContainer component={Paper}
                            className='rounded-none shadow-none'>
                <Table size={"small"} aria-label="a table">
                    <TableRow>
                        <TableCell className='font-bold' style={{width:150}}>Name</TableCell>
                        <TableCell>{selectedUser.first_name} {selectedUser.last_name}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className='font-bold' style={{width:150}}>Email</TableCell>
                        <TableCell>{selectedUser.email}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className='font-bold' style={{width:150}}>Mobile</TableCell>
                        <TableCell>{selectedUser.mobile}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className='font-bold' style={{width:150}}>Address</TableCell>
                        <TableCell>{selectedUser.street}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className='font-bold' style={{width:150}}>Organization</TableCell>
                        <TableCell>{selectedUser.organization}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className='font-bold' style={{width:150}}>City</TableCell>
                        <TableCell>{selectedUser.city}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className='font-bold' style={{width:150}}>State</TableCell>
                        <TableCell>{selectedUser.state}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className='font-bold' style={{width:150}}>Pincode</TableCell>
                        <TableCell>{selectedUser.pincode}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className='font-bold' style={{width:150}}>Country</TableCell>
                        <TableCell>{selectedUser.country}</TableCell>
                    </TableRow>
                </Table>
            </TableContainer>
            <div className='p-12 text-gray-900'>
                {!selectedUser.has_email_verified && (
                    <p className='text-orange text-16'>Email is not verified.</p>
                )}
                {type === "APPROVE" && (
                    <Button
                        onClick={() => doApproveUser(selectedUser.id)}
                        size={"small"} variant={"contained"}
                        color={"secondary"}
                        className='mt-24 mb-8 flex block mx-auto'>
                        Confirm Approve
                    </Button>
                )}
                {type === "DENY" && (
                    <Button
                        onClick={() => doDenyUser(selectedUser.id)}
                        size={"small"} variant={"contained"}
                        color={"secondary"}
                        className='mt-24 mb-8 flex block mx-auto'>
                        Confirm Deny
                    </Button>
                )}
                {type === "DETAIL" && (
                    <div>
                        {selectedUser.has_blocked ?
                            <Button
                                onClick={() => doUnblockUser(selectedUser.id)}
                                size={"small"} variant={"outlined"}
                                className='mt-24 mb-8 flex rounded-none text-red-light'>
                                Unblock</Button>
                            :
                            <Button
                                onClick={() => doBlockUser(selectedUser.id)}
                                size={"small"} variant={"outlined"}
                                className='mt-24 mb-8 flex rounded-none text-red-light'>
                                Block</Button>
                        }
                        <Typography component='p' className='text-gray-500'>After <span className='bold'>Blocking</span>,
                             user can't access/login to this account.</Typography>


                        <Button
                            onClick={() => window.confirm("Are you sure?") && doDeleteUser(selectedUser.id)}
                            size={"small"} variant={"outlined"}
                            className='mt-24 mb-8 flex rounded-none text-red-light'>
                            Delete User<Icon className='ml-2'
                                             fontSize={"small"}>delete</Icon></Button>
                        <Typography component='p' className='text-gray-500'>On <span className='bold'>Delete User</span>,
                            it deletes all user's data, IDS's Invoices etc.</Typography>

                    </div>
                )}
            </div>
            {loading && (
                <LinearProgress color={"secondary"}/>
            )}
        </Dialog>

    )
}
