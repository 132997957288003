import JSZip from "jszip";

// const download = () => {
//     zip.generateAsync({type: "blob"}).then(function (blob) {
//         saveAs(blob, "test_archive.zip");
//     });
// };


export const generateZip = (files, zipFileName) => {
    if (files.length) {
        let zip = JSZip();
        for (const file of files) {
            zip.file(`${file.name}`, file, {
                binary: true
            });
        }
        return zip.generateAsync({type: "blob"}).then(content => {
            // saveAs(content, "test_archive.zip");
            return new File([content], zipFileName)
        });
    }
    return null
}

export const readZIP = (content) => {
    let zip = new JSZip();
    // more files !
    return zip.loadAsync(content)
        .then(async function (zip) {
            let files = []
            for (let key in zip.files) {
                await zip.file(key).async('blob').then(blob => {
                    let file = new File([blob], key);
                    files = files.concat([file])
                })
            }
            return files;
        });
}

export const getDateFormatted = (date) => {
    if(date)
    return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
    else
        return '2020-01-01'
}

export const mapOrder = (order, key) => (a, b) => order.indexOf(a[key]) > order.indexOf(b[key]) ? 1 : -1;
