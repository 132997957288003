import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {API_BASE_URL, isNumber, MAX_DAYS_FOR_GENERATING_CONSOLIDATED_INVOICE} from "../../fuse-configs/constants";
import axios from "axios";
import {FusePageCarded, FusePageSimple} from "../../../@fuse";
import {
    AppBar,
    Button, CircularProgress, Drawer, FormControl, Icon, IconButton, InputLabel, MenuItem,
    OutlinedInput,
    Paper,
    Table, TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Toolbar,
    Typography,
    Select, LinearProgress, Tooltip
} from "@material-ui/core";
import {saveAs} from 'file-saver';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    DatePicker
} from '@material-ui/pickers';
import {getDateFormatted} from "../../utils";
import {isEmail} from "validator";
import {getApplication, showMessage} from "../../store/actions";

export default function Invoices(props) {
    const dispatch = useDispatch();
    const user = useSelector(({auth}) => auth.user);

    const [Invoices, setInvoices] = useState([]);

    // progress
    const [progress, setProgress] = useState(false);

    // search
    const [search, setSearch] = useState('');
    const [searchField, setSearchField] = useState('INVOICE_NUMBER');

    // filters
    const [filterStatus, setFilterStatus] = useState('');
    const [filterGeneratedBy, setFilterGeneratedBy] = useState('');
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    // pagination
    const [next, setNext] = useState(null);
    const [prev, setPrev] = useState(null);


    const getInvoices = (url = null, search = null, filterGeneratedBy, status, fromDate, toDate) => {
        setProgress(true)
        let base_url = API_BASE_URL + 'invoices/?limit=100&generated_by=' + filterGeneratedBy + '&status=' + status + '&search_field=' + searchField

        url = url || base_url

        if (search) {
            url = base_url + '&search=' + search
            if (search.slice(-1) === " ") {
                url = base_url + '&search=' + search.trim() + '&exact_search=True'
            }
        }

        if (fromDate && toDate) {
            url = url + '&from_date=' + getDateFormatted(fromDate) + '&to_date=' + getDateFormatted(toDate)
        }

        axios.get(url + `&timestamp=${new Date().getTime()}`)
            .then(res => {
                setInvoices(res.data.results)
                setProgress(false)
                setNext(res.data.next);
                setPrev(res.data.previous);
            }).catch(err => {
            setProgress(false)
        })
    }


    // HANDLE INTERACTIONS

    const handleSearchFieldUpdate = (event) => {
        setSearchField(event.target.value);
        doSearch('');
    }

    const handleFilterGeneratedBy = async (text) => {
        setFilterGeneratedBy(text);
        setInvoices([]);
        setNext(null)
        setPrev(null)
        getInvoices(null, search, text, filterStatus, fromDate, toDate);
    }
    const handleFilterStatus = async (text) => {
        setFilterStatus(text);
        setInvoices([]);
        setNext(null)
        setPrev(null)
        getInvoices(null, search, filterGeneratedBy, text, fromDate, toDate);
    }

    const doSearch = (searchText) => {
        setSearch(searchText);
        setInvoices([]);
        setNext(null)
        setPrev(null)
        getInvoices(null, searchText, filterGeneratedBy, filterStatus, fromDate, toDate);
    }

    const handleFromDate = (date) => {
        setFromDate(date)
        if (toDate) {
            getInvoices(null, search, filterGeneratedBy, filterStatus, date, toDate)
        }
    }
    const handleToDate = (date) => {
        setToDate(date)
        if (fromDate) {
            getInvoices(null, search, filterGeneratedBy, filterStatus, fromDate, date)
        }
    }

    // reset

    // edit amount
    const makeAmountEditable = (invoice) => {
        const invoices = Invoices.map(inv => {
            if (invoice.id === inv.id) {
                inv.editableAmount = true;
            }
            return inv
        })
        setInvoices(invoices)
    }

    const handleEditAmount = (invoice, amount) => {
        if (isNumber.test(amount)) {
            const invoices = Invoices.map(inv => {
                if (invoice.id === inv.id) {
                    inv.editableAmount = true;
                    inv.amount = amount;
                }
                return inv
            })
            setInvoices(invoices)
        } else if (amount === "") {
            const invoices = Invoices.map(inv => {
                if (invoice.id === inv.id) {
                    inv.editableAmount = true;
                    inv.amount = amount;
                }
                return inv
            })
            setInvoices(invoices)
        }
    }

    // get file API
    const get_invoice_file = (invoice) => {
        setProgress(true)
        axios.get(API_BASE_URL + 'invoices/' + invoice.id + '/get_pdf_file/' + `?timestamp=${new Date().getTime()}`, {
            responseType: 'blob'
        })
            .then(res => {
                saveAs(res.data, `INVOICE_${invoice.invoice_number}.pdf`);
                setProgress(false)
            }).catch(err => {
            setProgress(false)
        })
    }

    const getConsolidatedInvoice = (url) => {

        if( toDate && fromDate) {
            const diff = (toDate - fromDate) / (1000 * 60 * 60 * 24)
            if(diff>MAX_DAYS_FOR_GENERATING_CONSOLIDATED_INVOICE && MAX_DAYS_FOR_GENERATING_CONSOLIDATED_INVOICE !== "0" && MAX_DAYS_FOR_GENERATING_CONSOLIDATED_INVOICE !== ""){
                dispatch(showMessage({message: "Please select date range in "+MAX_DAYS_FOR_GENERATING_CONSOLIDATED_INVOICE+" days.", variant: 'warning'}))
                return;
            }
        } else if(MAX_DAYS_FOR_GENERATING_CONSOLIDATED_INVOICE !== "0" && MAX_DAYS_FOR_GENERATING_CONSOLIDATED_INVOICE !== "") {
            dispatch(showMessage({message: "Please select date range in "+MAX_DAYS_FOR_GENERATING_CONSOLIDATED_INVOICE+" days.", variant: 'warning'}))
            return;
        }

        if (user.role === 'admin') {
            if (!isEmail(search)) {
                dispatch(showMessage({message: "Please select a user from search bar.", variant: 'warning'}))
                return
            }
        }
        setProgress(true)
        let base_url = API_BASE_URL + 'invoices/get-consolidated-invoice/?limit=100&generated_by=' + filterGeneratedBy + '&status=' + filterStatus + '&search_field=' + searchField

        url = url || base_url

        if (search) {
            url = base_url + '&search=' + search
            if (search.slice(-1) === " ") {
                url = base_url + '&search=' + search.trim() + '&exact_search=True'
            }
        }

        if (fromDate && toDate) {
            url = url + '&from_date=' + getDateFormatted(fromDate) + '&to_date=' + getDateFormatted(toDate)
        }

        axios.get(url + `&timestamp=${new Date().getTime()}`)
            .then(res => {
                saveAs(new Blob([res.data]), 'INVOICE_CONSOLIDATED.pdf');
                setProgress(false)
            }).catch(err => {
            setProgress(false)
        })
    }

    const handleUpdateStatus = (invoice, newStatus) => {
        setProgress(true)
        axios.post(API_BASE_URL + 'invoices/' + invoice.id + '/update-status/', {
            new_status: newStatus
        }).then(res => {
            dispatch(showMessage({
                message: 'Invoice status updated',
                variant: 'success',
                autoHideDuration: 1000

            }))
            invoice.status = newStatus
            const invoices = Invoices.map(inv => {
                if (invoice.id === inv.id) {
                    inv.status = newStatus;
                }
                return inv
            })
            setInvoices(invoices)
            setProgress(false)
        }).catch(err => {
            setProgress(false)
        })
    }
    const handleUpdateAmount = (invoice) => {
        if (!isNumber.test(invoice.amount)) {
            dispatch(showMessage({
                message: 'Please enter a valid amount.',
                variant: 'warning',
                autoHideDuration: 1000
            }))
            return
        }
        setProgress(true)
        axios.post(API_BASE_URL + 'invoices/' + invoice.id + '/update-amount/', {
            new_amount: invoice.amount
        }).then(res => {
            setProgress(false)
            const invoices = Invoices.map(inv => {
                if (invoice.id === inv.id) {
                    inv.amount = invoice.amount;
                    inv.editableAmount = false;
                }
                return inv
            })
            setInvoices(invoices)
            dispatch(showMessage({
                message: 'Invoice amount updated',
                variant: 'success',
                autoHideDuration: 1000

            }))
        }).catch(err => {
            dispatch(showMessage({
                message: 'Invoice amount updation failed',
                variant: 'error',
                autoHideDuration: 1000

            }))
            setProgress(false)
        })
    }


    useEffect(() => {
            getInvoices();
            if(user.role === 'admin'){
                setSearchField('USER')
            }
        },
        []
    )


    return <FusePageSimple


        contentToolbar={
            <div className='flex items-center flex-row w-full px-24 h-128 bg-blue-lightest'>
                <Icon color={'secondary'}>receipt</Icon>
                <Typography color={"secondary"} className='ml-8 text-xl flex-1'>All Invoices</Typography>

                <Select
                    value={searchField}
                    onChange={handleSearchFieldUpdate}
                    variant={"outlined"}
                    className='h-48 text-16 bg-white'
                >
                    {user.role === 'admin' &&
                    <MenuItem value={"USER"}>User</MenuItem>
                    }
                    <MenuItem value={"INVOICE_NUMBER"}>Invoice Number</MenuItem>
                    <MenuItem value={"APPLICATION_NUMBER"}>Application Number</MenuItem>
                </Select>

                <OutlinedInput className='text-16 ml-4 w-full max-w-sm my-4 bg-white h-48'
                               placeholder={"Search"}
                               value={search}
                               onChange={(e) => doSearch(e.target.value)}
                />
            </div>
        }


        content=
            {<div>
                <div className='w-full px-24'>
                    <TableContainer component={Paper} className='w-full'>
                        {progress && (<LinearProgress color={"secondary"}/>)}

                        {/*FILTERS*/}
                        <div className='flex flex-row items-center p-12' colSpan={7}>
                            <Typography className='text-16 pr-12'>Status</Typography>
                            <Select
                                value={filterStatus}
                                onChange={(event) => handleFilterStatus(event.target.value)}
                                className='text-14 bg-white'
                                displayEmpty
                            >
                                <MenuItem value={""}>All</MenuItem>
                                <MenuItem value={"PENDING_PAYMENT"}>Pending Payment</MenuItem>
                                <MenuItem value={"PAID"}>Paid</MenuItem>
                                <MenuItem value={"UNDER_REVIEW"}>Under Review</MenuItem>
                            </Select>
                            <Typography className='text-16 pl-24  pr-12'>Generated By</Typography>
                            <Select
                                value={filterGeneratedBy}
                                onChange={(event) => handleFilterGeneratedBy(event.target.value)}
                                className='text-14'
                                displayEmpty

                            >
                                <MenuItem value={""}>All</MenuItem>
                                <MenuItem value={"admin"}>Admin</MenuItem>
                                <MenuItem value={"user"}>User</MenuItem>
                            </Select>

                            <div className='pl-32 pb-16'>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>

                                    <DatePicker
                                        label="From date"
                                        value={fromDate}
                                        onChange={handleFromDate}
                                        animateYearScrolling
                                        disableFuture
                                        format="dd-MM-yyyy"
                                        clearable
                                        minDate='2020-01-01'
                                    />
                                    <DatePicker

                                        label="To date"
                                        className='ml-12'
                                        value={toDate}
                                        onChange={handleToDate}
                                        animateYearScrolling
                                        disableFuture
                                        format="dd-MM-yyyy"
                                        clearable
                                        minDate='2020-01-01'
                                    />
                                </MuiPickersUtilsProvider>
                                {fromDate && toDate && fromDate > toDate && (
                                    <Typography className='text-red'><b>From date</b> must be lower than <b>To date </b></Typography>
                                )}

                            </div>
                            <Button
                                variant={"contained"}
                                className='ml-16'
                                color={"secondary"}
                                onClick={() => getConsolidatedInvoice(null)}
                                size={"small"}
                            >
                                Get Consolidated Invoice
                            </Button>

                        </div>
                        <Table style={{overflow: 'auto'}} size={"small"} aria-label="simple table">
                            <TableHead>
                                <TableRow className='font-bold'>
                                    <TableCell className='' style={{width: 48}}>SN.</TableCell>
                                    <TableCell className='px-0' align={"center"}>Invoice No.</TableCell>
                                    <TableCell className='px-0'>Application No.</TableCell>
                                    <TableCell className='px-0' align="center" style={{minWidth: 120}}>Date</TableCell>
                                    {user.role === 'admin' && (
                                        <TableCell className='px-0' align="left">User</TableCell>
                                    )}
                                    <TableCell className='px-4' align="center">
                                        <Typography className='font-bold'>Generated By</Typography>
                                    </TableCell>
                                    <TableCell className='px-4' align="center">
                                        <Typography className='font-bold'>Amount</Typography>
                                    </TableCell>


                                    <TableCell align="center">
                                        <Typography className='font-bold'>Status</Typography>
                                    </TableCell>
                                    <TableCell align="left">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Invoices.map((row, index) => (
                                    <TableRow key={row.id}>
                                        <TableCell className='' style={{width: 48}}>
                                            {index + 1}
                                        </TableCell>
                                        <TableCell className='px-0' align='center'>
                                            {row.invoice_number}
                                        </TableCell>
                                        <TableCell className='px-4'>
                                            <Typography className='text-16'>{row.application_number}</Typography>
                                        </TableCell>
                                        <TableCell className='px-4' align="center"
                                                   style={{minWidth: 120}}>{row.date}</TableCell>
                                        {user.role === 'admin' && (
                                            <TableCell className='px-4'>
                                                <Typography>{row.user_email}</Typography>
                                            </TableCell>
                                        )}
                                        <TableCell className='px-0' align={"center"}>
                                            <Typography>{row.generated_by}</Typography>
                                        </TableCell>
                                        <TableCell className='px-0' align={"center"}>
                                            {user.role === 'admin' && row.status === "UNDER_REVIEW" && row.editableAmount ?
                                                <OutlinedInput
                                                    placeholder='Enter Amount'
                                                    className='w-64'
                                                    value={row.amount}
                                                    onChange={(e) => handleEditAmount(row, e.target.value)}
                                                    onKeyDown={e => e.keyCode === 13 && handleUpdateAmount(row)}
                                                />
                                                :
                                                <Typography
                                                    onClick={() => makeAmountEditable(row)}>{row.amount}</Typography>
                                            }


                                        </TableCell>
                                        <TableCell className='px-0' align={"center"}>
                                            {user.role === 'admin' ? <Select
                                                    value={row.status}
                                                    onChange={(event) => handleUpdateStatus(row, event.target.value)}
                                                    className='text-14'
                                                >
                                                    <MenuItem value={"PENDING_PAYMENT"}>Pending</MenuItem>
                                                    <MenuItem value={"PAID"}>Paid</MenuItem>
                                                    <MenuItem value={"UNDER_REVIEW"}>Review</MenuItem>
                                                </Select>
                                                :
                                                <Typography>{row.status_display}</Typography>

                                            }

                                        </TableCell>
                                        <TableCell>
                                            <Tooltip title="Click to download invoice PDF" aria-label="add" placement="top">
                                            <IconButton
                                                onClick={() => get_invoice_file(row)}
                                                color={'secondary'}
                                            >
                                                <Icon fontSize={"small"}>save_alt</Icon>
                                            </IconButton>
                                            </Tooltip>
                                            {user.role === 'admin' && row.status === "UNDER_REVIEW" &&
                                            <Tooltip title="Click on amount to edit" aria-label="add" placement="top">
                                            <IconButton
                                                color={'secondary'}
                                                className='cursor-default'
                                            >
                                                <Icon fontSize={"small"}>edit</Icon>
                                            </IconButton>
                                            </Tooltip>
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <div className='flex flex-row justify-center p-12 my-12'>
                        <Button
                            onClick={() => prev && getInvoices(prev)}
                            variant={"outlined"}
                            size={"small"}
                            color={"primary"}
                            className='rounded-none'
                            disabled={!prev}
                        >
                            Previous
                        </Button>
                        <Button
                            onClick={() => next && getInvoices(next)}
                            variant={"outlined"}
                            size={"small"}
                            color={"primary"}
                            className='rounded-none ml-8'
                            disabled={!next}
                        >
                            Next
                        </Button>
                    </div>


                </div>

            </div>}

    />
}
