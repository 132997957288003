import React from "react";
import {
    withStyles,
    Typography, Card, ListItem, Paper, Button,
} from '@material-ui/core';
import {connect} from "react-redux";
import {FusePageSimple} from "../../../@fuse";
import {fade} from '@material-ui/core/styles/colorManipulator';
import {Link} from "react-router-dom";

const styles = theme => ({
    layoutRoot: {},
    header: {
        background: 'linear-gradient(to right, ' + theme.palette.primary.dark + ' 0%, ' + theme.palette.primary.main + ' 100%)',
        width: '100%'
    },
    listItem: {
        color: 'inherit!important',
        textDecoration: 'none!important',
        height: 40,
        width: '80%',
        borderRadius: '0px 20px 20px 0px',
        paddingLeft: 24,
        paddingRight: 12,
        '&.active': {
            // backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.main + '!important',
            pointerEvents: 'none',
            '& .list-item-icon': {
                color: 'inherit'
            }
        }
    },
    contentWrapper: {
        display: 'flex',
        padding: 0,
        flexDirection: 'row',
        flex: '1 1 100%',
        zIndex: 10,
        height: window.innerHeight - 112,
        background   : `linear-gradient(to bottom, ${fade(theme.palette.background.paper, 0.8)} 0,${fade(theme.palette.background.paper, 0.6)} 20%,${fade(theme.palette.background.paper, 0.8)})`
        // background: theme.palette.background.default
    }
});

class Home extends React.Component {

    state = {
        tab_value: 0
    }

    handleTab = (e, value) => {
        this.setState({tab_value: value})
    }

    render() {
        const {contentToolbar, user} = this.props;
        return (
            <FusePageSimple
                classes={{
                    header: "min-h-72 h-72 bg-gray-100",
                    // toolbar: "min-h-48 h-48",
                    // rightSidebar: "w-48",
                    // content     : classes.content,
                }}
                contentToolbar={contentToolbar}

                content={
                    <div className='p-24'>
                        <Typography className='text-xl font-medium text-center block mb-24' color={"secondary"}>Welcome, {user.first_name}!</Typography>
                        <Paper className='shadow'>
                            <ListItem className='text-16 border-b hover:bg-gray-100' component={Link} to='/what-is-hatch-ids-app'>What is Hatch IDS?</ListItem>
                            <ListItem className='text-16 border-b hover:bg-gray-100' component={Link} to='/about-us-app'>About Us</ListItem>
                            <ListItem className='text-16 border-b hover:bg-gray-100' component={Link} to='/faqs-app'>FAQs</ListItem>
                            <ListItem className='text-16 border-b hover:bg-gray-100' component={Link} to='/contact-app'>Contact Us</ListItem>
                            <ListItem className='text-16 border-b hover:bg-gray-100' component={Link} to='/feedback-app'>Feedback</ListItem>
                            <ListItem className='text-16 border-b hover:bg-gray-100' component={Link} to='/terms-app'>Terms & Conditions</ListItem>
                            <ListItem className='text-16' component={Link} to='/privacy-app'>Privacy Policy</ListItem>
                        </Paper>
                    </div>
                }

                innerScroll
                onRef={instance => {
                    this.pageLayout = instance;
                }}
            />
        )
    };
}

function mapStateToProps({fuse, auth}) {
    return {
        user: auth.user.data
    }
}

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps)(Home));
