import React from 'react';
import TermsAndConditions from "./TermsAndConditions";
import Landing from "./Landing";
import {authRoles} from "../../auth";
import Blog from "./Blog";
import CareersAndInternships from "./CareersAndInternships";
import ContactUs from "./ContactUs";
import PrivacyPolicy from "./PrivacyPolicy";
import Feedback from "./Feedback";
import FAQs from "./FAQs";
import WhatIsHatchIDS from "./WhatIsHatchIDS";
import AboutUs from "./AboutUs";
import BlogDetail from "./BlogDetail";

export const CoreConfig = {
    routes: [
        {
            path: '/terms-and-conditions',
            component: TermsAndConditions,
            settings: {
                layout: {
                    config: {
                        navbar: {
                            display: false
                        },
                        toolbar: {
                            display: false
                        },
                        footer: {
                            display: true,
                            style   : 'fixed',
                        }
                    }

                }
            },
        },
        // {
        //     path: '/blog',
        //     component: Blog,
        //     exact:true,
        //     settings: {
        //         layout: {
        //             config: {
        //                 navbar: {
        //                     display: false
        //                 },
        //                 toolbar: {
        //                     display: false
        //                 },
        //                 footer: {
        //                     display: true,
        //                     style   : 'static',
        //                 }
        //             }
        //
        //         }
        //     },
        // },
        // {
        //     path: '/blog/:id',
        //     component: BlogDetail,
        //     settings: {
        //         layout: {
        //             config: {
        //                 navbar: {
        //                     display: false
        //                 },
        //                 toolbar: {
        //                     display: false
        //                 },
        //                 footer: {
        //                     display: true,
        //                     style   : 'static',
        //                 }
        //             }
        //
        //         }
        //     },
        // },
        {
            path: '/careers-and-internships',
            component: CareersAndInternships,
            settings: {
                layout: {
                    config: {
                        navbar: {
                            display: false
                        },
                        toolbar: {
                            display: false
                        },
                        footer: {
                            display: true,
                            style   : 'fixed',
                        }
                    }

                }
            },
        },
        {
            path: '/contact-us',
            component: ContactUs,
            settings: {
                layout: {
                    config: {
                        navbar: {
                            display: false
                        },
                        toolbar: {
                            display: false
                        },
                        footer: {
                            display: true,
                            style   : 'fixed',
                        }
                    }

                }
            },
        },
        {
            path: '/privacy-policy',
            component: PrivacyPolicy,
            settings: {
                layout: {
                    config: {
                        navbar: {
                            display: false
                        },
                        toolbar: {
                            display: false
                        },
                        footer: {
                            display: true,
                            style   : 'fixed',
                        }
                    }

                }
            },
        },
        {
            path: '/feedback',
            component: Feedback,
            settings: {
                layout: {
                    config: {
                        navbar: {
                            display: false
                        },
                        toolbar: {
                            display: false
                        },
                        footer: {
                            display: true,
                            style   : 'fixed',
                        }
                    }

                }
            },
        },
        {
            path: '/FAQs',
            component: FAQs,
            settings: {
                layout: {
                    config: {
                        navbar: {
                            display: false
                        },
                        toolbar: {
                            display: false
                        },
                        footer: {
                            display: true,
                            style   : 'fixed',
                        }
                    }

                }
            },
        },
        {
            path: '/what-is-hatch-ids',
            component: WhatIsHatchIDS,
            settings: {
                layout: {
                    config: {
                        navbar: {
                            display: false
                        },
                        toolbar: {
                            display: false
                        },
                        footer: {
                            display: true,
                            style   : 'fixed',
                        }
                    }

                }
            },
        },
        {
            path: '/about-us',
            component: AboutUs,
            settings: {
                layout: {
                    config: {
                        navbar: {
                            display: false
                        },
                        toolbar: {
                            display: false
                        },
                        footer: {
                            display: true,
                            style   : 'fixed',
                        }
                    }

                }
            },
        },
        // {
        //     path: '/terms-and-conditions-app',
        //     component: ()=><TermsAndConditions footer={false} />,
        //     settings: {
        //         layout: {
        //             config: {
        //                 navbar: {
        //                     display: true
        //                 },
        //                 toolbar: {
        //                     display: true
        //                 },
        //                 footer: {
        //                     display: true
        //                 }
        //             }
        //
        //         }
        //     },
        // },
    ]
};
