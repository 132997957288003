import React from 'react';
import Settings from "./Settings";

export const SettingsConfig = {
    routes: [
        {
            path: '/settings',
            component: Settings,
            settings: {
                layout: {
                    config: {
                        navbar: {
                            display: true
                        },
                        toolbar: {
                            display: true
                        },
                        footer: {
                            display: true
                        }
                    }

                }
            },
        },
    ]
};
