import React from 'react';
import authRoles from '../../auth/authRoles'
import IDSRequests from "./IDSRequests";
import IDSs from "./IDSs";

export const IDSConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: true
                },
                toolbar: {
                    display: true
                },
                footer: {
                    display: false,
                }
            }
        }
    },
    auth: authRoles.user,
    routes: [
        {
            path: '/ids-requests',
            exact:true,
            component: IDSRequests
        },
        {
            path: '/ids-list',
            exact:true,
            component: IDSs
        },
    ]
};






/*
*
*
*
*
* LOGIN - guest
*   HOME - user, admin
*   USERS - admin
*
*
*
* */
