import React, {Component} from 'react'
import equal from 'fast-deep-equal'

import {withStyles, Card, CardContent, Typography} from '@material-ui/core';
import {FuseAnimate} from '@fuse';
import {Link, withRouter} from 'react-router-dom';
import {Button, InputAdornment, Icon} from '@material-ui/core';
import {TextFieldFormsy} from '@fuse';
import Formsy from 'formsy-react';
import {bindActionCreators} from 'redux';
import connect from 'react-redux/es/connect/connect';
import * as authActions from 'app/auth/store/actions';
import LinearProgress from "@material-ui/core/LinearProgress";
import Footer from "../core/components/Footer";
import Header from "../core/components/Header";

const styles = theme => ({
    root: {
        color: theme.palette.primary.contrastText
    }
});

class Login extends Component {

    state = {
        canSubmit: false,
        loader: false,
    };

    setLoader = (val) => {
        this.setState({loader: val});
    };

    form = React.createRef();

    disableButton = () => {
        this.setState({canSubmit: false});
    };

    enableButton = () => {
        this.setState({canSubmit: true});
    };

    onSubmit = (model) => {
        this.props.submitLogin(model);
    };


    componentWillReceiveProps(nextProps) {
        if (!equal(this.state.loader, nextProps.login.loader)) // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
        {
            this.setLoader(nextProps.login.loader);
        }
    }


    render() {
        const {login} = this.props;
        const {canSubmit} = this.state;
        return (
            <div>
                <Header/>
                <div className="pb-160 h-full items-center">


                <FuseAnimate animation={{translateY: [0, '10%']}}>

                    <Card className="w-full max-w-400 m-auto rounded-12 mt-24 shadow-md hover:shadow-none">
                        <CardContent className="flex flex-col items-center justify-center p-32 ">

                            <img src='/assets/logo-2.png' className='w-64 mb-12'/>

                            <Typography variant="h6" className="text-center md:w-full mb-32 font-semibold"
                                        color={"secondary"}>Welcome back! Sign In</Typography>


                            <div className="w-full">
                                <Formsy
                                    onValidSubmit={this.onSubmit}
                                    onValid={this.enableButton}
                                    onInvalid={this.disableButton}
                                    ref={(form) => this.form = form}
                                    className="flex flex-col justify-center w-full"
                                >
                                    <TextFieldFormsy
                                        className="mb-16"
                                        type="text"
                                        name="email"
                                        label="Registered Email Address"
                                        validations={{
                                            isEmail: true,
                                            maxLength: 50,
                                        }}
                                        validationErrors={{
                                            isEmail: 'You have to type valid email',
                                            maxLength: 'You can not type in more than 50 characters',
                                        }}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end"><Icon className="text-20"
                                                                                               color="action">email</Icon></InputAdornment>,
                                        }}
                                        variant="outlined"
                                        required

                                    />

                                    <TextFieldFormsy
                                        className="mb-16"
                                        type="password"
                                        name="password"
                                        label="Password"
                                        validations={{
                                            minLength: 8
                                        }}
                                        validationErrors={{
                                            minLength: 'Min character length is 8'
                                        }}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end"><Icon className="text-20"
                                                                                               color="action">vpn_key</Icon></InputAdornment>
                                        }}
                                        variant="outlined"
                                        required
                                    />
                                    <Link className="font-medium " to="/reset-password">Forgot Password?</Link>


                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="secondary"
                                        className="w-full mx-auto mt-12 normal-case"
                                        aria-label="LOG IN"
                                        disabled={!canSubmit}
                                        value="legacy"
                                    >
                                        Login
                                    </Button>

                                </Formsy>


                            </div>


                            <div className="flex flex-col pt-12 justify-start w-full">
                                <span className="font-medium">Don't have an account? <Link
                                    to='/signup'>Sign Up</Link></span>
                            </div>

                        </CardContent>
                        {login.loading && (
                            <LinearProgress color="secondary"/>
                        )}
                    </Card>
                </FuseAnimate>
                </div>

                <Footer/>

            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        submitLogin: authActions.submitLogin
    }, dispatch);
}

function mapStateToProps({auth}) {
    return {
        login: auth.login,
        user: auth.user
    }
}

export default withStyles(styles, {withTheme: true})(withRouter(connect(mapStateToProps, mapDispatchToProps)(Login)));
