import React from 'react';
import BlogList from './BlogList';
import {authRoles} from "../../auth";
import Blog from "./Blog";

const BlogConfigOutsideApp = {
	settings: {
		layout: {
			config: {
				footer: {
					display: true
				},
				navbar:{
					display:false
				},
				toolbar:{
					display:false
				},
			}
		}
	},
	routes: [
		// I Can change the url of Blog from Here
		{
			path: '/insights',
			component: (props)=><BlogList {...props} outside={true}/>,
			exact:true,
		},
		{
			path: '/blogs/:id/',
			component: (props)=><Blog {...props} outside={true}/>
		},

	]
};

export default BlogConfigOutsideApp;

/**
 * Lazy load Example
 */
/*
import React from 'react';

const ExampleConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/example',
            component: React.lazy(() => import('./Example'))
        }
    ]
};

export default ExampleConfig;

*/
