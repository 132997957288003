import React from 'react';

function LeftSideLayout2()
{
    return (
        <React.Fragment>

        </React.Fragment>
    );
}

export default LeftSideLayout2;
