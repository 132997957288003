import React from "react";
import {
    Icon,
    IconButton,
    Typography,
    Button,
    CircularProgress,
    OutlinedInput,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Select, MenuItem, Divider
} from '@material-ui/core';
import {connect} from "react-redux";
import {FusePageSimple} from "../../../@fuse";
import axios from "axios";
import {API_BASE_URL} from "../../fuse-configs/constants";
import {bindActionCreators} from "redux";
import {showMessage} from "../../store/actions";
import UserDialog from "./UserDialog";

class Users extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,

            isSearchOpen: false,

            isOpenUserDialog: false,
            selectedUser: null,
            dialogType: "DETAIL",

            users: [],
            prevURL: null,
            nextURL: null,
        }
    }

    componentDidMount() {
        this.getUsers(null, null)
    }

    // API CALLS
    getUsers = (url = null, search = null) => {
        this.setState({loading: true});
        let base_url = API_BASE_URL + 'auth/users/?ordering=-id&limit=100'

        url = url || base_url

        if (search) {
            url = base_url + '&search=' + search
        }

        axios.get(url + `&timestamp=${new Date().getTime()}`)
            .then(res => {
                this.setState({
                    users: res.data.results,
                    loading: false,
                    prevURL: res.data.previous,
                    nextURL: res.data.next,
                })
                window.scrollTo(0, 0)
            }).catch(err => {
            this.setState({loading: false})
        })
    }


    // Actions
    doRefresh = () => {
        this.setState({
            users: [],
            prevURL: null,
            nextURL: null,
            isSearchOpen: false
        })
        this.getUsers(null, null)
    }

    doSearch = (searchText) => {
        this.setState({
            users: [],
            prevURL: null,
            nextURL: null,
        })
        this.getUsers(null, searchText)
    }

    goPrev = () => {
        const {prevURL} = this.state;
        this.getUsers(prevURL, null)
    }

    goNext = () => {
        const {nextURL} = this.state;
        this.getUsers(nextURL, null)
    }

    // from userdialog
    onApproveUser = (user_id) => {
        const users = this.state.users.map(user => {
            if (user.id === user_id) {
                user.has_approved = true;
                return user
            }
            return user
        })
        this.setState({
            isOpenUserDialog: false,
            selectedUserForApproval: null,
            users: users,
        })
    }
    onDenyUser = (user_id) => {
        const users = this.state.users.filter(user => {
            return user.id !== user_id
        })
        this.setState({
            isOpenUserDialog: false,
            selectedUserForApproval: null,
            users: users,
        })
    }
    onDeleteUser = (user_id) => {
        const users = this.state.users.filter(user => {
            return user.id !== user_id
        })
        this.setState({
            isOpenUserDialog: false,
            selectedUserForApproval: null,
            users: users,
        })
    }

    // BLOCK - UNBLOCK user
    onBlockUser = (user_id) => {
        const users = this.state.users.map(user => {
            if (user.id === user_id) {
                user.has_blocked = true;
                return user
            }
            return user
        })
        this.setState({
            isOpenUserDialog: false,
            selectedUserForApproval: null,
            users: users,
        })
    }

    onUnblockUser = (user_id) => {
        const users = this.state.users.map(user => {
            if (user.id === user_id) {
                user.has_blocked = false;
                return user
            }
            return user
        })
        this.setState({
            isOpenUserDialog: false,
            selectedUserForApproval: null,
            users: users,
        })
    }


    // UPDATE MINOR CHANGES

    openUserDialog = (selectedUser, type = "DETAIL") => {
        this.setState({
            isOpenUserDialog: !this.state.isOpenUserDialog,
            selectedUser: selectedUser,
            dialogType: type
        })
    }

    toggleSearch = () => {
        this.setState({isSearchOpen: !this.state.isSearchOpen})
    }

    toggleUserDialog = () => {
        this.setState({isOpenUserDialog: !this.state.isOpenUserDialog})
    }


    userDialog = () => {
        const {selectedUser, dialogType} = this.state;
        if (selectedUser == null)
            return
        return (
            <UserDialog
                selectedUser={selectedUser}
                onApproveUser={this.onApproveUser}
                onDenyUser={this.onDenyUser}
                onBlockUser={this.onBlockUser}
                onUnblockUser={this.onUnblockUser}
                isOpenUserDialog={this.state.isOpenUserDialog}
                toggleUserDialog={this.toggleUserDialog}
                type={dialogType}
            />
        )
    }

    render() {
        const {users, loading, nextURL, prevURL, isSearchOpen} = this.state;
        return (
            <FusePageSimple
                contentToolbar={
                    <div className='flex items-center flex-row w-full px-24 h-128 bg-blue-lightest'>
                        <Icon color={'secondary'}>person</Icon>
                        <Typography color={"secondary"} className='ml-8 text-xl flex-1'>All Users</Typography>

                        <OutlinedInput
                            className='ml-4 w-full text-16 max-w-sm my-4 bg-white h-48'
                            placeholder={"Search here"}
                            color={"primary"}
                            onChange={e => this.doSearch(e.target.value)}
                        />
                    </div>

                }

                content={
                    <div className='w-full px-24'>
                        <TableContainer hidden={!users.length} component={Paper} className='shadow'>
                            <Table aria-label="a table">
                                <TableHead>
                                    <TableRow className='font-bold'>
                                        <TableCell>Email ID</TableCell>
                                        <TableCell align="left">First Name</TableCell>
                                        <TableCell align="left">Last Name</TableCell>
                                        <TableCell align="left">Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {users.map((row) => (
                                        <TableRow key={row.id}>
                                            <TableCell
                                                onClick={() => this.openUserDialog(row, "DETAIL")}
                                                component="th" scope="row" className='text-blue cursor-pointer'>
                                                {row.email}
                                            </TableCell>
                                            <TableCell align="left">{row.first_name}</TableCell>
                                            <TableCell align="left">{row.last_name}</TableCell>
                                            <TableCell align="left">
                                                {!row.has_email_verified && (
                                                    <p className='text-orange text-16'>Email is not verified.</p>
                                                )}
                                                {!row.has_approved && (
                                                    <Button
                                                        variant={"outlined"}
                                                        disabled={row.has_approved}
                                                        size={"small"}
                                                        className='rounded-none text-orange mr-4'
                                                        onClick={() => this.openUserDialog(row, "DENY")}
                                                    >
                                                        Deny
                                                        <Icon fontSize={"small"} className='ml-2'>close</Icon>
                                                    </Button>
                                                )}
                                                {row.has_blocked ?
                                                    <Typography className='text-lg italic'>Blocked</Typography>
                                                    :
                                                    <Button
                                                        variant={"outlined"}
                                                        disabled={row.has_approved}
                                                        color={"secondary"}
                                                        size={"small"}
                                                        className='rounded-none'
                                                        onClick={() => this.openUserDialog(row, "APPROVE")}
                                                    >
                                                        {row.has_approved ? "Approved" : "Approve"}
                                                        <Icon fontSize={"small"} className='ml-2'>check</Icon>
                                                    </Button>
                                                }

                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        {
                            loading && (
                                <CircularProgress
                                    color={"secondary"}
                                    size={24}
                                    className='text-center mt-24 block mx-auto'
                                />
                            )
                        }

                        {!loading && !users.length && (
                            <Typography className='m-24 text-lg text-center opacity-75'>
                                No users found.
                            </Typography>
                        )}

                        <div hidden={!users.length} className='flex flex-row justify-center p-12 my-12'>
                            <Button
                                onClick={this.goPrev}
                                variant={"outlined"}
                                size={"small"}
                                color={"primary"}
                                className='rounded-none'
                                disabled={!prevURL}>
                                Previous
                            </Button>
                            <Button
                                onClick={this.goNext}
                                variant={"outlined"}
                                size={"small"}
                                color={"primary"}
                                className='rounded-none ml-8'
                                disabled={!nextURL}
                            >
                                Next
                            </Button>
                        </div>

                        {this.userDialog()}

                    </div>
                }

                // innerScroll
                onRef={instance => {
                    this.pageLayout = instance;
                }}
            />
        )
    }
}

function mapStateToProps({fuse, auth}) {
    return {
        user: auth.user
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        showMessage: showMessage
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);
