
import React, {useEffect} from 'react';
import {Chip, CircularProgress, Icon, ListItem, Paper, TextField} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import FusePageSimple from "../../../@fuse/components/FusePageLayouts/simple/FusePageSimple";
import {useDispatch, useSelector} from "react-redux";
import {getPosts} from "../../store/actions";
import moment from "moment";
import history from '@history'
import Header from "../core/components/Header";

import ReactHtmlParser from 'react-html-parser';
import Helmet from "react-helmet";


/*function Blog(props) {
  const dispatch = useDispatch();
  console.log(props)

  var id= props.match.params.id
  //alert(urlKey.id)
  //var splitKey = urlKey.split('-')
  //if (splitKey.length > 1){
  //  var urlKey = splitKey[1]
  //}

  const post = useSelector(state=>{
    const {id} = props.match.params
    //alert('this is the para '+id)
    //console.log(state.blog.postsById[id],'check this plz')
    //console.log(state)
    //console.log(id)
    //console.log(state.blog.post
    return state.blog.postsById[id]
  })
  //alert(post)
  useEffect(                                                                                                                                                  ()=>{
        const {id} = props.match.params
       //const {id} = urlKey
        //alert('dispatcinh '+{id})
        dispatch(getPosts({id}))
      },
      []
  )
  */
  function Blog(props) {
    const dispatch = useDispatch();
  
    const post = useSelector(state=>{
      const {id} = props.match.params
      return state.blog.postsById[id]
    })
    useEffect(
        ()=>{
          const {id} = props.match.params
          dispatch(getPosts({id}))
        },
        []
    )
  
   /* const goToBlogList = () => {
        if(props.outside){
            history.push("/blogs");
            return;
        }
      history.push("/blog");
    }*/

  const goToBlogList = () => {
      if(props.outside){
          history.push("/insights");
          return;
      }
    history.push("/insights");
  }

  if(!post){
    return <CircularProgress size={24} className='self-center mt-64'/>
  }
  return (
      <>
          {props.outside && <Header/>}
        
          <div className={props.outside ? 'max-w-2xl mx-auto':''}>
          <FusePageSimple
          content={
              <div className={props.outside ?"p-0 py-24 w-full max-w-xl" : 'p-24 w-full max-w-xl'}>
                  <Paper className="w-full mb-16 shadow p-16">
                      <Typography  color="textSecondary" className="items-center flex">
                        
                          <div>
                          </div>
                        
                        {(post.meta).split('+-').map((item) => {
                             
                         return     <Helmet>
                              {ReactHtmlParser(item)}
                             </Helmet>
                        })}
                        
                        
                        
                        <Typography component="a" className="cursor-pointer"><a href='/'>Home</a></Typography>
                          <Icon  fontSize={"small"}>navigate_next</Icon><span className="cursor-pointer" onClick={goToBlogList} >Go Back</span></Typography>
                    
                      <Typography className="text-4xl">{post.title}</Typography>
                      <Typography component="a">{moment(post.date_created).format('LL')}</Typography>
                      <Typography dangerouslySetInnerHTML={{__html:post.content}}/>
                  </Paper>
              </div>
          }
      />
          </div>

      </>
  );
}

export default Blog;