import * as Actions from '../actions';

const initialState = {
    role: [],
    data: {
        first_name: '',
        last_name: '',
        email: '',
        mobile: '',
        is_active: '',

        street: '',
        city: '',
        pincode: '',
        state: '',
        country: ''
    }
};

const user = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_USER_DATA: {
            return {
                ...initialState,
                data: action.payload,
                role: action.payload.role
            };
        }
        case Actions.REMOVE_USER_DATA: {
            return {
                ...initialState,
            };
        }
        case Actions.USER_LOGGED_OUT: {
            return initialState;
        }
        default: {
            return state
        }
    }
};

export default user;
