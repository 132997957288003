import React from 'react';
import authRoles from '../../auth/authRoles'
import Invoices from "./Invoices";

export const InvoiceConfig = {
    settings: {
        layout: {
            config: {

                navbar: {
                    display: true
                },
                toolbar: {
                    display: true
                },
                footer: {
                    display: false,
                }
            }
        }
    },
    auth: authRoles.user,
    routes: [
        {
            path: '/invoice-list',
            exact:true,
            component: Invoices
        },
    ]
};






/*
*
*
*
*
* LOGIN - guest
*   HOME - user, admin
*   USERS - admin
*
*
*
* */
