import * as Actions from 'app/store/actions';
import jwtService from 'app/services/jwtService';

export const REGISTER_START = 'REGISTER_START';
export const REGISTER_ERROR = 'REGISTER_ERROR';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';

export function submitRegister({first_name, last_name, password, email, mobile, street, city, pincode, state, country, organization}, signupCallback)
{
    return (dispatch) => {
        dispatch({type:REGISTER_START})
        jwtService.createUser({
            first_name,
            last_name,
            mobile,
            password,
            email,

            street,
            city,
            pincode,
            state,
            country,
            organization,
        })
            .then((user) => {
                    dispatch(Actions.showMessage({
                        message: "Account created successfully. Please verify email and kindly wait for the administrator approval.",
                        variant:'success'
                    }));
                signupCallback();
                    return dispatch({
                        type: REGISTER_SUCCESS
                    });
                }
            )
            .catch(error => {
                dispatch(Actions.showMessage({message: error, variant:'error'}));
                return dispatch({
                    type: REGISTER_ERROR,
                    payload: error
                });
            });
    }
}
