import React, {Component} from 'react';
import {withStyles, Card, CardContent, Typography} from '@material-ui/core';
import {FuseAnimate, TextFieldFormsy} from '@fuse';
import {Link, Redirect, withRouter} from 'react-router-dom';
import classNames from 'classnames';
import {Button, Divider, InputAdornment, Icon} from '@material-ui/core';
import TextField from "@material-ui/core/TextField";
import axios from 'axios';
import LinearProgress from "@material-ui/core/LinearProgress";
import Formsy from "formsy-react";
import {bindActionCreators} from "redux";
import * as authActions from "../../auth/store/actions";
import {showMessage} from "../../store/actions";
import {connect} from "react-redux";
import {API_BASE_URL, CAPTCHA_SITE_KEY} from "../../fuse-configs/constants";
import Header from "../core/components/Header";
import Footer from "../core/components/Footer";
import ReCAPTCHA from "react-google-recaptcha";

const styles = theme => ({
    root: {
        // background: 'linear-gradient(to right, ' + theme.palette.primary.dark + ' 0%, ' + darken(theme.palette.primary.dark, 0.5) + ' 100%)',
        background: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText
    }
});

class ResetPassword extends Component {

    state = {
        canSubmit: false,
        token: null,
        loading: false,
        captchaValue: null,
        success: false,
        isTokenValid: null
    };

    componentDidMount() {
        let params = new URLSearchParams(this.props.location.search);
        const token = params.get("token");
        this.setState({
            token: token,
        })
        token && this.validateToken(token);
    }

    validateToken = (token) => {
        axios.get(API_BASE_URL + 'auth/validate-token/', {
            headers: {Authorization: `Bearer ${token}`}
        })
            .then(res => {
                this.setState({isTokenValid: true})
            }).catch(err => {
            this.setState({isTokenValid: false})
        })
    }

    form = React.createRef();

    disableButton = () => {
        this.setState({canSubmit: false});
    };

    enableButton = () => {
        this.setState({canSubmit: true});
    };

    onEmailSubmit = ({email}) => {
        if (email !== '') {
            this.startLoader()
            axios.post(API_BASE_URL + "auth/forgot-password/", {
                email: email,
            })
                .then(res => {
                    this.form.reset()
                    this.stopLoader()
                    this.props.showMessage({
                        autoHideDuration: 12000,
                        message: "Follow the link sent on your email ID to change the password",
                        variant: 'success'
                    })
                    this.setState({success: true})
                })
                .catch(err => {
                    this.stopLoader()
                    this.form.reset()
                    this.props.showMessage({
                        message: (err.response && err.response.data && err.response.data.msg) || "Something went wrong",
                        variant: 'error'
                    })
                })
        }
    };

    handleChangePassword = (e) => {
        this.setState({
            new_password: e.target.value,
        })
    };
    onChangePasswordSubmit = ({password}) => {
        this.startLoader()
        const token = this.state.token;
        if (token !== '' && password !== '') {
            axios.post(API_BASE_URL + 'auth/change-password/', {
                password: password,
                token: token,
            }, {
                headers: {Authorization: `Bearer ${token}`}
            })
                .then(res => {
                    this.stopLoader()
                    this.form.reset()
                    this.props.showMessage({
                        message: "Password changed successfully!",
                        variant: 'success'
                    })
                    this.setState({success: true})
                })
                .catch(err => {
                    this.stopLoader()
                    this.form.reset()
                    this.props.showMessage({
                        message: (err.response && err.response.data && err.response.data.msg) || "Something went wrong",
                        variant: 'error'
                    })
                })
        }
    };

    startLoader = () => {
        this.setState({loading: true})
    }
    stopLoader = () => {
        this.setState({loading: false})
    }

    //captcha
    onCaptchaChanged = (value) => {
        this.setState({captchaValue: value})
    }


    render() {
        let params = new URLSearchParams(this.props.location.search);
        const {classes} = this.props;
        const {canSubmit, loading, token, captchaValue, success, isTokenValid} = this.state;

        return (
            <div>
                <Header/>
                <div className="pb-320 h-full pt-72 items-center">
                    <FuseAnimate animation={{translateY: [0, '10%']}}>

                        <Card className="w-full max-w-400 mx-auto rounded-12 mt-24 shadow-md hover:shadow-none">

                            {this.state.loader && (
                                <LinearProgress color="primary"/>
                            )}
                            <CardContent className="flex flex-col items-center justify-center p-32 ">

                                {!success && (
                                    <Typography variant="h6" className="text-center md:w-full mb-32 font-bold">
                                        {token !== null ?
                                            "Enter the new password"
                                            :
                                            "Enter registered Email ID for reset password."

                                        }

                                    </Typography>

                                )}


                                <div className="w-full">
                                    {token === null ? (
                                            <div>
                                                {success === false ?
                                                    <Formsy
                                                        onValidSubmit={this.onEmailSubmit}
                                                        onValid={this.enableButton}
                                                        onInvalid={this.disableButton}
                                                        ref={(form) => this.form = form}
                                                        className="flex flex-col justify-center w-full"
                                                    >
                                                        <TextFieldFormsy
                                                            className="mb-16"
                                                            type="text"
                                                            name="email"
                                                            label="Registered Email Address"
                                                            validations={{
                                                                isEmail: true,
                                                            }}
                                                            validationErrors={{
                                                                isEmail: 'You have to type valid email',
                                                            }}
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="end"><Icon
                                                                    className="text-20"
                                                                    color="action">email</Icon></InputAdornment>,
                                                                autocomplete: "off"
                                                            }}
                                                            variant="outlined"
                                                            required

                                                        />
                                                        <ReCAPTCHA
                                                            sitekey={CAPTCHA_SITE_KEY}
                                                            onChange={this.onCaptchaChanged}
                                                        />

                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            color="secondary"
                                                            className="w-full mx-auto mt-12 normal-case"
                                                            aria-label="Reset Password"
                                                            disabled={!(canSubmit && captchaValue)}
                                                            value="legacy"
                                                        >
                                                            Reset Password
                                                        </Button>

                                                    </Formsy>
                                                    :
                                                    <Typography className='text-lg italic p-5 font-medium text-green-light'>
                                                        Follow the link sent on your email ID to change the password
                                                    </Typography>
                                                }
                                            </div>

                                        ) :

                                        (
                                            <div>
                                                {success === false && isTokenValid &&
                                                <Formsy
                                                    onValidSubmit={this.onChangePasswordSubmit}
                                                    onValid={this.enableButton}
                                                    onInvalid={this.disableButton}

                                                    ref={(form) => this.form = form}
                                                    className="flex flex-col justify-center w-full"
                                                >
                                                    <TextFieldFormsy
                                                        className="mb-16"
                                                        type="password"
                                                        name="password"
                                                        label="New Password"
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="end"><Icon
                                                                className="text-20"
                                                                color="action">vpn_key</Icon></InputAdornment>,
                                                            autocomplete: "off"
                                                        }}
                                                        variant="outlined"
                                                        required

                                                    />


                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="secondary"
                                                        className="w-full mx-auto mt-12 normal-case"
                                                        aria-label="Change Password"
                                                        disabled={!canSubmit}
                                                        value="legacy"
                                                    >
                                                        Reset Password
                                                    </Button>

                                                </Formsy>
                                                }
                                                {success === true &&
                                                <Typography
                                                    className='text-lg italic p-5 font-medium text-green-light'>
                                                    Password changed successfully!
                                                </Typography>
                                                }

                                                {isTokenValid === false &&
                                                <Typography
                                                    className='text-lg italic p-5 font-medium text-gray-700'>
                                                    Invalid Token, Please reset the password again to change password.
                                                </Typography>
                                                }
                                            </div>

                                        )}


                                </div>


                                <div className="flex flex-col pt-12 justify-start w-full">
                                    <span className="font-medium text-center"><Link to='/login'>Go Back to login</Link></span>
                                </div>

                            </CardContent>
                            {loading && (
                                <LinearProgress color={"secondary"}/>
                            )}
                        </Card>
                    </FuseAnimate>
                </div>
                <Footer/>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        submitRegister: authActions.submitRegister,
        showMessage: showMessage
    }, dispatch);
}

function mapStateToProps({auth}) {
    return {
        register: auth.register,
        user: auth.user
    }
}

export default withStyles(styles, {withTheme: true})(withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPassword)));
