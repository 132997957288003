import React from 'react';
import BlogList from './BlogList';
import {authRoles} from "../../auth";

const BlogConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			path: '/insight',
			component: React.lazy(() => import('./BlogList')),
			exact:true,
		},
		{
			path: '/blog/:id/',
			component: React.lazy(() => import('./Blog'))
		},

	]
};

export default BlogConfig;

/**
 * Lazy load Example
 */
/*
import React from 'react';

const ExampleConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/example',
            component: React.lazy(() => import('./Example'))
        }
    ]
};

export default ExampleConfig;

*/
