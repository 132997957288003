import React from "react";
import {Button, Chip, CircularProgress, Dialog, Icon, IconButton, ListItem, Paper, TextField} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import FusePageSimple from "../../../@fuse/components/FusePageLayouts/simple/FusePageSimple";
import {deleteBlog, getCategories, getPosts} from "../../store/actions";
import {mapOrder} from "../../utils";
// import { getCategories } from "./store/categorySlice";
// import { getBlogs, updateBlog } from "./store/blogSlice";
import _ from 'lodash';
import moment from "moment";
import history from "@history";
import {DEFAULT_IDS_AMOUNT_ADMIN} from "../../fuse-configs/constants";
import Header from "../core/components/Header";
import Helmet from "react-helmet";

function BlogList(props) {
    const dispatch = useDispatch();
    // const blogs = useSelector(({ blog }) => blog.blogs);
    const categories = useSelector((state) =>
        _.values(_.pick(state.blog.categoriesById, state.blog.categories.ids))
            .sort(mapOrder(state.blog.categories.ids, 'id'))
    );
    const posts = useSelector((state) =>
        _.values(_.pick(state.blog.postsById, state.blog.posts.ids))
            .sort(mapOrder(state.blog.posts.ids, 'id'))
    );

    const role = useSelector(({auth}) => auth.user.role)

    const nextPostURL = useSelector(({blog}) => blog.posts.next)

    const [search, setSearch] = useState("");
    const [category, setCategory] = useState(null);

    const [selectedBlog, setSelectedBlog] = useState({});

    const [deleteDialog, setDeleteDialog] = useState(false);

    useEffect(
        () => {
            const query = new URLSearchParams(props.location.search);
            setSearch(query.get("search") || "");
            setCategory(query.get("category"));
            dispatch(getCategories());
        },
        []
    );

    useEffect(
        () => {
            console.log('FETCHING POSTS')
            dispatch(getPosts({search, category: category && category.id}));
        },
        [category, search]
    )

    const loadMore = () => {
        console.log('LOADING MORE', nextPostURL)
        nextPostURL && dispatch(getPosts({next_url: nextPostURL}));
    }

    const handleCategory = (category) => {
        setCategory(category)
    }

    const handleDelete = (blog) => {
        setSelectedBlog(blog);
        setDeleteDialog(true)
    }
    const confirmDelete = () => {
        dispatch(deleteBlog(selectedBlog.id))
        setSelectedBlog({});
        setDeleteDialog(false)
    }
    const cancelDelete = () => {
        setSelectedBlog({});
        setDeleteDialog(false)
    }

    const goToBlogDetail = (id) => {
      //alert('From blog detail')
        if(props.outside){
            history.push("/blogs/" + id);
            return;
        }
        history.push("/blog/" + id);
    }
    const goToBlogUpdate = (id) => {
        history.push("/blog/update/" + id);
    }

    return (
        <>
            {props.outside&&<Header/>}
            <Helmet>
                <title> Information Disclosure Statement (IDS) Blogs</title>
                <meta name="description" content="Latest blogs from HatchIDS experts on Information Disclosure Statement (IDS) management solution topics." />
                <link rel="canonical" href="https://www.hatchids.com/blogs" />
            </Helmet>
            <div className={props.outside ? 'max-w-2xl mx-auto':''}>
                <FusePageSimple
                    content={
                        <div className={props.outside ?"p-0 py-24 pr-24 w-full" : 'p-24 w-full'}>
                            <div className="flex items-center">
                                <Typography className="text-3xl flex-1">Insights</Typography>
                                {/* Commented It To remove Search blog section */}
                                {/*<TextField
                                    className="w-full max-w-sm"
                                    label="Search blogs"
                                    variant="filled"
                                    value={search}
                                    onChange={e => setSearch(e.target.value)}
                                />*/}
                            </div>
                            <div className="pt-24">
                                <div className="pb-12 flex">
                                    {category && <Chip size="small"
                                                       label={"Category : " + category.name}
                                                       className=""
                                                       color="primary"
                                                       onDelete={() => setCategory(null)}/>}
                                    {search !== "" && <Chip size="small"
                                                            label={"Search : " + search}
                                                            className="ml-12"
                                                            color="primary"
                                                            onDelete={() => setSearch("")}/>}

                            </div>

                                {posts.map(item => (
                                    <Paper className="w-full mb-16 shadow p-12">
                                        <Typography className="text-2xl">{item.title}</Typography>
                                        <Typography component="a">{moment(item.date_created).format('LL')}</Typography>
                                        <Typography className="">{item.content.replace(/<[^>]*>?/gm, '').substring(0, 300)}...

                                            <a
                                                className='cursor-pointer'
                                                onClick={() => goToBlogDetail(item.id)}> continue
                                                Reading</a>
                                        </Typography>
                                        <div className='flex flex-row mt-12 items-center'>
                                            {/* Commented It To remove categories */}
                                            {/*<div className='flex-1'>
                                                {item.category &&
                                                <Chip label={item.category.name} variant="outlined" color={'secondary'}/>
                                                }
                                            </div>*/}
                                            {
                                                role === 'admin' && (
                                                    <>
                                                        <IconButton onClick={() => goToBlogUpdate(item.id)}
                                                                    color='secondary'
                                                                    className='shadow'><Icon>edit</Icon></IconButton>
                                                        <IconButton onClick={() => handleDelete(item)}
                                                                    color='secondary'
                                                                    className='shadow ml-12'><Icon>delete</Icon></IconButton>

                                                    </>
                                                )
                                            }
                                        </div>
                                    </Paper>
                                ))}
                                <Button color={'secondary'}
                                        variant='contained'
                                        disabled={!nextPostURL}
                                        className='self-center mx-auto block'
                                        onClick={loadMore}>Load More</Button>
                            </div>
                            <Dialog maxWidth={"md"}
                                    open={deleteDialog}
                                    onClose={() => setDeleteDialog(false)}
                            >
                                <div className='p-24 bg-gray-200'>
                                    <Typography className='text-lg pb-12'>Confirm delete?</Typography>
                                    {selectedBlog.id && (
                                        <Paper className="w-full mb-16 shadow p-12">
                                            <Typography className="text-2xl">{selectedBlog.title}</Typography>
                                            <Typography component="a">{moment(selectedBlog.date_created).format('LL')}</Typography>
                                            <Typography className="">{selectedBlog.content.replace(/<[^>]*>?/gm, '').substring(0, 300)}...

                                                <a
                                                    className='cursor-pointer'
                                                    onClick={() => goToBlogDetail(selectedBlog.id)}> continue
                                                    Reading</a>
                                            </Typography>
                                            <div className='flex flex-row mt-12 items-center'>
                                                <div className='flex-1'>
                                                    {selectedBlog.category &&
                                                    <Chip label={selectedBlog.category.name}
                                                          variant="outlined"
                                                          color={'secondary'}/>
                                                    }
                                                </div>
                                            </div>
                                        </Paper>

                                    )}
                                    <div className='flex justify-end'>
                                        <Button className='rounded-none' variant={"text"} color={"secondary"}
                                                onClick={cancelDelete}
                                        >Cancel</Button>
                                        <Button className='rounded-none ml-12' variant={"contained"} color={"secondary"}
                                                onClick={confirmDelete}
                                        >Delete</Button>
                                    </div>

                                </div>
                            </Dialog>

                        </div>
                    }
                    
                />

            </div>

        </>
    );
}

export default BlogList;
